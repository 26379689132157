import type { Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { BindingEngine, bindable, inject } from 'aurelia-framework';
import { SearchableList } from '../../Components/Lists/SearchableList';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { TeamDialog } from '../../Users/TeamDialog';
import { UserService } from '../../Users/UserService';
import { UserAuthorisationService } from '../UserAuthorisationService';

@inject(BindingEngine)
export class TeamList {
    protected list: SearchableList<Team>;
    private teams: Array<Team> = [];
    private _dataChangedSub: Subscription;

    protected getMembersInTeam(team: Team) {
        if (!team.members) return 0;
        return UserService.getAllUsersOfTeam(team).length;
    }

    protected getDeactivatedMembersOfTeam(team: Team) {
        const members = UserService.getAllUsersOfTeam(team);

        return members.filter(m => UserAuthorisationService.isDeactivated(m.email, UserAuthorisationService.userAuths[m.email]));
    }

    @bindable() activeDate: string | undefined;

    protected get notFoundText(): TranslationKey {
        if (this.tab.id === 'active') return 'no-items.no-active-teams';

        if (this.tab.id === 'temporary') return 'no-items.no-temporary-teams';

        return 'no-items.no-teams';
    }

    constructor(private bindingEngine: BindingEngine) {}
    attached() {
        this.loadTeams();
        this.list = new SearchableList<Team>(this.bindingEngine, this.teams, ['name']);
        this._dataChangedSub = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.hasAnyType('team') || e.hasAnyType('accountuser')) {
                //TODO instead of binding a new array update the items inside the array
                this.loadTeams();
            }
        });
    }
    protected loadTeams() {
        const teams = UserService.getTeams(this.activeDate).filter(t => {
            let include = false;

            if (this.tab.id === 'active' && !t.activeFrom) include = true;

            if (this.tab.id === 'temporary' && !!t.activeFrom) include = true;

            if (this.tab.id === 'all') include = true;

            return include;
        });

        this.teams.splice(0, this.teams.length, ...teams);
    }

    protected view = async (team: Team) => {
        await new TeamDialog(team).show();
    };

    protected tabs = [
        { id: 'active', label: 'Active' },
        { id: 'temporary', label: 'Temporary' },
        { id: 'all', label: 'All' },
    ];

    protected tab = this.tabs[0];

    protected tabChanged(tab: (typeof this.tabs)[number]) {
        this.tab = tab;
        this.loadTeams();
    }

    protected detached() {
        if (this._dataChangedSub) this._dataChangedSub.dispose();
        if (this.list) this.list.dispose();
    }
}
