import type {
    Account,
    Attachment,
    Customer,
    FrequencyData,
    ISession,
    Job,
    JobGroup,
    Notification,
    SqueegeeCreditLedgerEntry,
    StoredObject,
    TaxConfig,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    AlphanumericCharColourDictionary,
    FrequencyType,
    FrequencyUtils,
    StoredObjectResourceTypeNames,
    TagType,
    calculateSqueegeeCreditsRequiredForSms,
    copyObject,
    ease,
    easeInOut,
    getTaxConfig,
    notNullUndefinedEmptyOrZero,
    sortByDateAsc,
    splitName,
    to2dp,
    wait,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from './ApplicationState';
import { ImageAttachmentQualityOptions } from './Attachments/ImageAttachmentQualityOptions';
import { ImageAttachmentResolutionOptions } from './Attachments/ImageAttachmentResolutionOptions';
import type { TImageAttachmentQuality } from './Attachments/TImageAttachmentQuality';
import type { TImageAttachmentResolution } from './Attachments/TImageAttachmentResolution';
import AutomaticPaymentsService from './AutomaticPayments/AutomaticPaymentsService';
import { Data } from './Data/Data';
import { EmailTemplateService } from './EmailTemplates/EmailTemplateService';
import { BGLoaderEvent } from './Events/BGLoaderEvent';
import { GlobalFlags } from './GlobalFlags';
import type { IImageData, IImageThumbnailData } from './IImageData';
import { InvoiceService } from './Invoices/InvoiceService';
import { Logger } from './Logger';
import { NotifyUserMessage } from './Notifications/NotifyUserMessage';
import { ValidationErrorMessage } from './Notifications/ValidationErrorMessage';
import { RoundService } from './Rounds/RoundService';
import { ScheduleService } from './Schedule/ScheduleService';
import { Api } from './Server/Api';
import { RethinkDbAuthClient } from './Server/RethinkDbAuthClient';
import type { StandardMessageModel } from './StandardMessageModel';
import { TransactionService } from './Transactions/TransactionService';
import { t } from './t';

export class Utilities {
    public static generatePortalUrl(uniqueUrl: string) {
        const portalUrl = Api.currentHost.includes('localhost')
            ? `http://localhost:3006/${uniqueUrl}`
            : Api.currentHost.includes('dev.squeeg.ee')
            ? `https://dev.squeeg.ee/portal/${uniqueUrl}`
            : `https://squeeg.ee/portal/${uniqueUrl}`;

        return portalUrl;
    }

    public static switchAccount(dataEmail: string) {
        Utilities.goToRootUrl({ queryStringParams: { dataEmail } });
    }

    public static getUnsubscribeUrl(customerId: string) {
        return `${Api.apiEndpoint}/unsubscribe?account=${encodeURIComponent(ApplicationState.account.uuid)}&id=${encodeURIComponent(
            customerId
        )}`;
    }
    public static goToRootUrl({
        applicationRouteWithLeadingSlash,
        queryStringParams,
    }: {
        applicationRouteWithLeadingSlash?: string;
        queryStringParams?: Record<string, string | number | boolean>;
    }) {
        if (RethinkDbAuthClient.session?.freeDevice) {
            if (!queryStringParams) queryStringParams = {};
            queryStringParams.ass = true;
        }

        const queryString =
            (queryStringParams &&
                `?${Object.keys(queryStringParams)
                    .map(key => `${key}=${queryStringParams?.[key]?.toString() || ''}`)
                    .join('&')}`) ||
            '';

        let host = '';
        let path = '';

        let url: string;
        if (GlobalFlags.isAppleMobileApp) {
            host = document.location.href.split('#')[0];
            if (applicationRouteWithLeadingSlash) {
                if (applicationRouteWithLeadingSlash.startsWith('/'))
                    applicationRouteWithLeadingSlash = applicationRouteWithLeadingSlash.substring(1);
                const prefix = !applicationRouteWithLeadingSlash?.startsWith('#') ? '#' : '';
                path = `${prefix}${applicationRouteWithLeadingSlash}`;
            }
            url = `${host}${path}${queryString}`;
        } else if (GlobalFlags.isAndroidMobileDevice && GlobalFlags.isMobileApp) {
            if (applicationRouteWithLeadingSlash) path = applicationRouteWithLeadingSlash;
            const prefix = !applicationRouteWithLeadingSlash?.startsWith('#') ? '#' : '';
            url = `https://squeegee-mobile-app/index.html${queryString}${prefix}${path}${queryString}`;
        } else {
            if (applicationRouteWithLeadingSlash) path = applicationRouteWithLeadingSlash;
            url = `${host}${path}${queryString}`;
        }

        document.location.href = url;
    }

    public static TEXT_TO_COLOUR_DICTIONARY = new AlphanumericCharColourDictionary();
    public static textToColour(text?: string, notFoundColor = '#BBBBBB') {
        if (!text) return notFoundColor;
        return Utilities.TEXT_TO_COLOUR_DICTIONARY[text.toUpperCase().substring(0, 1)] || notFoundColor;
    }
    public static async getShortUrl(originalUrl: string, host?: string) {
        try {
            if (!host) {
                const endpoint = Api.apiEndpoint;
                if (endpoint.startsWith('http://localhost:30')) {
                    host = endpoint;
                } else {
                    switch (endpoint) {
                        case 'https://dev.squeeg.ee':
                            host = endpoint;
                            break;
                        case 'https://staging.squeeg.ee':
                            host = endpoint;
                            break;
                        default:
                            host = 'https://sqgee.com';
                            break;
                    }
                }
            }
            const shortUrlRequest = await Api.post<{ shortUrl: string }>(
                Api.apiEndpoint,
                '/api/shorturl',
                { originalUrl },
                undefined,
                false,
                { timeout: 10000 }
            );
            const path = shortUrlRequest?.data?.shortUrl;
            const shortUrl = path && host ? `${host}${path}` : originalUrl;
            return shortUrl || originalUrl;
        } catch (error) {
            Logger.error(error);
            return originalUrl;
        }
    }
    public static async getStandardMessageModel<TAdditionalModelType extends { [name: string]: string }>({
        customer,
        isHtml,
        additionalModelProperties,
        templateText,
    }: {
        customer: Customer;
        isHtml: boolean;
        additionalModelProperties?: TAdditionalModelType;
        templateText: string;
    }): Promise<StandardMessageModel & TAdditionalModelType> {
        try {
            const tokensInUse = getStandardMessageTokensInUse(templateText);

            const nextScheduleItem =
                !tokensInUse || tokensInUse.nextDate || tokensInUse.nextTime
                    ? ScheduleService.getNextScheduleItemForCustomer(customer._id)
                    : undefined;
            const nextDate = nextScheduleItem ? nextScheduleItem.formattedDate : ApplicationState.localise('general.unknown');
            const nextTime = nextScheduleItem && nextScheduleItem.occurrence.time ? nextScheduleItem.occurrence.time : 'any time';

            const { firstname, lastname, title } = splitName(customer.name);

            const customerName = customer.name || '';
            const customerRef = customer._externalId || '';
            const customerAddress = (customer.address && customer.address.addressDescription) || '';
            const businessName = ApplicationState.account.businessName;
            const email = customer.email || '';

            const inviteLink =
                tokensInUse.inviteLink || (tokensInUse as any).link ? await AutomaticPaymentsService.getInviteLink(customer._id) : '';

            let portalInviteLink = tokensInUse.portalInviteLink ? await ApplicationState.getPortalInviteLink(customer._externalId) : '';
            if (isHtml) {
                const params = { business: ApplicationState.account.businessName || '' };
                portalInviteLink = `<a href="${portalInviteLink}">${(t('message-link.portal-invite'), params)}</a>`;
            }

            const prepayTokens = templateText.match(/\[(prepayLink=(.*?))]/g) || [];
            const prepayLinks = {} as Record<string, string>;
            for (const prepayToken of prepayTokens) {
                const [, prepayLink, potentialPrepayAmountMatch] = /\[(prepayLink=(.*?))]/.exec(prepayToken) || [undefined, undefined];
                if (!prepayLink || prepayLinks[prepayLink]) continue;

                const potentialPrepayAmount = to2dp(Number(potentialPrepayAmountMatch) || 0);
                const prepayAmount = potentialPrepayAmount >= 1 && potentialPrepayAmount <= 10000 ? potentialPrepayAmount : undefined;
                if (!prepayAmount) continue;

                const prepayLinkUrl = await Utilities.getShortUrl(`${Api.apiEndpoint}/go/p/${customer._id}?amount=${prepayAmount * 100}`);

                prepayLinks[prepayLink] = prepayLinkUrl;
            }

            const payDueIncoicesToken = tokensInUse['payDueInvoices'];
            let payDueInvoicesLink = '';
            if (payDueIncoicesToken) {
                const balanceData = TransactionService.getCustomerBalance(customer._id, moment().format());
                const balanceAmount = balanceData && balanceData.overdue > 0 ? balanceData.overdue : 0;
                const balance = to2dp(balanceAmount || 0);
                const balanceToPay = balance >= 1 ? balance : undefined;
                if (balanceToPay) {
                    let balanceLink = await Utilities.getShortUrl(`${Api.apiEndpoint}/go/p/${customer._id}?amount=${balanceToPay * 100}`);
                    if (isHtml) {
                        const params = { amount: ApplicationState.currencySymbol() + balanceToPay.toFixed(2) };
                        balanceLink = `<a href="${balanceLink}">${ApplicationState.localise('message-link.pay-due-invoices', params)}</a>`;
                    }
                    payDueInvoicesLink = balanceLink;
                }
            }

            const payBalanceToken = tokensInUse['payBalance'];
            let payBalanceLink = '';
            if (payBalanceToken) {
                const balanceData = TransactionService.getCustomerBalance(customer._id);
                const balanceAmount = balanceData && balanceData.amount > 0 ? balanceData.amount : 0;
                const balance = to2dp(balanceAmount || 0);
                const balanceToPay = balance >= 1 ? balance : undefined;
                if (balanceToPay) {
                    let balanceLink = await Utilities.getShortUrl(`${Api.apiEndpoint}/go/p/${customer._id}?amount=${balanceToPay * 100}`);

                    if (isHtml) {
                        const params = { amount: ApplicationState.currencySymbol() + balanceToPay.toFixed(2) };
                        balanceLink = `<a href="${balanceLink}">${ApplicationState.localise('message-link.pay-balance', params)}</a>`;
                    }
                    payBalanceLink = balanceLink;
                }
            }

            const needsInvoices =
                tokensInUse.allInvoicesDownload ||
                tokensInUse.allInvoicesView ||
                tokensInUse.paidInvoicesDownload ||
                tokensInUse.paidInvoicesView ||
                tokensInUse.unpaidInvoicesDownload ||
                tokensInUse.unpaidInvoicesView;

            const allInvoiceTransactions = needsInvoices
                ? TransactionService.getCustomerInvoicesExcludingVoids(customer._id)
                      .filter(x => x.invoice && x.invoice.total > 0)
                      .sort(sortByDateAsc)
                : undefined;

            const allInvoicesView = allInvoiceTransactions ? Utilities.getInvoiceStatement(isHtml, allInvoiceTransactions, false) : '';
            const allInvoicesDownload = allInvoiceTransactions ? Utilities.getInvoiceStatement(isHtml, allInvoiceTransactions, true) : '';

            const unpaidInvoiceTransactions = allInvoiceTransactions
                ? allInvoiceTransactions.filter(t => t.invoice && !t.invoice.paid)
                : undefined;
            const unpaidInvoicesView = unpaidInvoiceTransactions
                ? Utilities.getInvoiceStatement(isHtml, unpaidInvoiceTransactions, false)
                : '';
            const unpaidInvoicesDownload = unpaidInvoiceTransactions
                ? Utilities.getInvoiceStatement(isHtml, unpaidInvoiceTransactions, true)
                : '';

            const paidInvoiceTransactions = allInvoiceTransactions
                ? allInvoiceTransactions.filter(t => t.invoice && t.invoice.paid)
                : undefined;
            const paidInvoicesView = paidInvoiceTransactions ? Utilities.getInvoiceStatement(isHtml, paidInvoiceTransactions, false) : '';
            const paidInvoicesDownload = paidInvoiceTransactions
                ? Utilities.getInvoiceStatement(isHtml, paidInvoiceTransactions, true)
                : '';

            const balanceData = tokensInUse.balance
                ? TransactionService.getCustomerBalance(customer._id, moment().format('YYYY-MM-DD'))
                : undefined;
            const balanceAmount = (balanceData && balanceData.amount) || 0;
            const balance = `${ApplicationState.currencySymbol()}${Math.abs(balanceAmount).toFixed(2)} ${
                balanceAmount < 0
                    ? ApplicationState.localise('general.in-credit')
                    : balanceAmount > 0
                    ? ApplicationState.localise('general.owing')
                    : ''
            }`;

            const standardModel: StandardMessageModel = {
                nextDate,
                nextTime,
                firstname,
                lastname,
                title,
                inviteLink,
                portalInviteLink,
                customerName,
                customerRef,
                customerAddress,
                'businessName': businessName || '',
                balance,
                allInvoicesView,
                allInvoicesDownload,
                unpaidInvoicesView,
                unpaidInvoicesDownload,
                paidInvoicesView,
                paidInvoicesDownload,
                email,
                'isHtml': isHtml ? 'true' : 'false',
                'signature': isHtml ? EmailTemplateService.getSignatureHtml() : '',
                'logo': isHtml ? EmailTemplateService.getLogoHtml() : '',
                'logoUrl': isHtml ? EmailTemplateService.getLogoUrl() : '',
                'prepayLink=?': '',
                'payBalance': payBalanceLink,
                'payDueInvoices': payDueInvoicesLink,
            };

            Object.assign(standardModel, { customerId: customer._id });
            Object.assign(standardModel, prepayLinks);

            if (inviteLink) Object.assign(standardModel, { link: inviteLink });
            if (additionalModelProperties) {
                Object.assign(standardModel, additionalModelProperties);
            }

            return standardModel as StandardMessageModel & TAdditionalModelType;
        } catch (error) {
            Logger.error('Failed to create message model', { error });
            throw error;
        }
    }

    private static getInvoiceStatement(isHtml: boolean, invoices: Array<Transaction>, pdf: boolean) {
        let rows = invoices.length
            ? invoices
                  .map(t => {
                      const date = t.invoice && moment(t.invoice.date).format('ll');
                      const url = InvoiceService.generateInvoiceUrl((t.invoice && t.invoice.referenceNumber) || '');
                      return isHtml
                          ? `<tr><td style="padding: 0 5px;">${
                                t.invoice?.invoiceNumber ? '#' + t.invoice?.invoiceNumber : 'Pending'
                            }</td><td style="padding: 0 5px;">${
                                t.invoice && t.invoice.paid ? 'paid' : 'unpaid'
                            }</td><td style="padding: 0 5px;">${ApplicationState.currencySymbol()}${t.amount.toFixed(
                                2
                            )}</td><td style="padding: 0 5px;">${date}</td><td style="padding: 0 5px;"><a target="_blank" href="${url}">${
                                pdf ? 'download' : 'view'
                            }</a></td><tr>`
                          : `#${t.invoice?.invoiceNumber || ''} ${
                                t.invoice && t.invoice.paid ? 'paid' : 'unpaid'
                            } ${ApplicationState.currencySymbol()}${t.amount.toFixed(2)} ${date} ${url}`;
                  })
                  .join('\n')
            : '';
        if (!isHtml) return rows;

        if (rows.length)
            rows =
                `<table cellspacing="0" style="border: 1px solid #CCCCCC;"><thead><tr style="color: #CCCCCC; ` +
                `background-color: #555555;"><th style="padding: 0 5px;">Invoice No.</th><th style="padding: 0 5px;">Status</th><th style="padding: 0 ` +
                `5px;">Amount</th><th style="padding: 0 5px;">Date</th><th style="padding: 0 5px;">${
                    pdf ? 'Download' : 'View'
                }</th><tr></thead><tbody>` +
                rows +
                '</tbody></table>';
        return rows;
    }

    /*  */
    public static invertHex(hex: string) {
        return (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(1).toUpperCase();
    }

    public static changeHexShade(hexColor: string, percentIncreaseOrDecrease: number) {
        return hexColor.replace(/../g, color =>
            ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + percentIncreaseOrDecrease)).toString(16)).substr(-2)
        );
    }

    public static hexFromString(text?: string, inverted = false, onWhite = true) {
        let hash = 0;
        text = text || '';
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }
        const c = (hash & 0x00ffffff).toString(16).toUpperCase();
        const hex = '00000'.substring(0, 6 - c.length) + c;
        return Utilities.changeHexShade(inverted ? Utilities.invertHex(hex) : hex, onWhite ? 30 : -30);
    }
    /*  */

    public static getEmailLengthText(value: string) {
        const characters = (value && value.length) || 0;
        const words = (value || '').split(' ').filter(x => x.trim()).length;
        return `${characters} character${characters > 1 ? 's' : ''} (${words} word${words > 1 ? 's' : ''})`;
    }

    public static getSmsLengthText(value: string) {
        const creditCheck = calculateSqueegeeCreditsRequiredForSms(value, true);
        return `${value?.length || 0} character${(value?.length || 0) > 1 ? 's' : ''} (approximately ${creditCheck.credits} credit${
            creditCheck.credits > 1 ? 's' : ''
        })`;
    }

    static generateUniqueRef(prefix = 'REF'): string {
        if (prefix && prefix.length !== 3) throw 'Prefix must be 3 characters long';
        const int = Utilities.randomInteger(0, 100000000);
        return prefix.toUpperCase() + int.toString();
    }

    public static isElementVisible(element: HTMLElement | null, tolerance: number) {
        if (!element) return false;

        const elementOuterEdge = element.getBoundingClientRect();

        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;

        const verticallyInView =
            elementOuterEdge.top <= windowHeight + tolerance && elementOuterEdge.top + elementOuterEdge.height >= -tolerance;
        const horizontallyInView =
            elementOuterEdge.left <= windowWidth + tolerance && elementOuterEdge.left + elementOuterEdge.width >= -tolerance;

        return verticallyInView && horizontallyInView;
    }

    public static randomInteger(min: number, max: number) {
        return Math.round(Math.random() * (max - min) + min);
    }

    public static get todayIso() {
        return moment().format('YYYY-MM-DD');
    }

    public static async resizeImage(originalImageDataUri: string, maxHeight = 500, maxWidth = 500) {
        return await new Promise<string>((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const copy = document.createElement('canvas');
                    const copyContext = copy.getContext('2d');

                    if (context && copyContext) {
                        let ratio = 1;
                        if (image.width > maxWidth) ratio = maxWidth / image.width;
                        else if (image.height > maxHeight) ratio = maxHeight / image.height;

                        copy.width = image.width;
                        copy.height = image.height;
                        copyContext.drawImage(image, 0, 0);

                        canvas.width = image.width * ratio;
                        canvas.height = image.height * ratio;
                        context.drawImage(copy, 0, 0, copy.width, copy.height, 0, 0, canvas.width, canvas.height);

                        return resolve(canvas.toDataURL());
                    }
                } catch (error) {
                    Logger.error('Error during resize image', error);
                    reject(error);
                }
            };
            image.src = originalImageDataUri;
        });
    }

    public static findAll(regex: RegExp, text: string) {
        if (!regex.global) throw 'You cannot match all on a non global regex';

        const matches: Array<string> = [];

        let match: RegExpMatchArray | null;

        while ((match = regex.exec(text))) {
            matches.push(match[0]);
        }

        return matches;
    }

    public static async readFileAsString(file: File) {
        const reader = new FileReader();
        const loader = new Promise(resolve => (reader.onload = data => resolve(data)));
        reader.readAsText(file);
        await loader;
        return <string>reader.result;
    }

    public static findEmojis(text: string) {
        return Utilities.findAll(
            new RegExp(
                '(?:[\\u2700-\\u27bf]|(?:\\ud83c[\\udde6-\\uddff]){2}|[\\ud800-\\udbff][\\udc00-\\udfff]|' +
                    '[\\u0023-\\u0039]\\ufe0f?\\u20e3|\\u3299|\\u3297|\\u303d|\\u3030|\\u24c2|\\ud83c[\\udd70-\\udd71]|' +
                    '\\ud83c[\\udd7e-\\udd7f]|\\ud83c\\udd8e|\\ud83c[\\udd91-\\udd9a]|\\ud83c[\\udde6-\\uddff]|' +
                    '[\\ud83c[\\ude01-\\ude02]|\\ud83c\\ude1a|\\ud83c\\ude2f|[\\ud83c[\\ude32-\\ude3a]|[\\ud83c[\\ude50-\\ude51]|' +
                    '\\u203c|\\u2049|[\\u25aa-\\u25ab]|\\u25b6|\\u25c0|[\\u25fb-\\u25fe]|\\u00a9|\\u00ae|\\u2122|\\u2139|' +
                    '\\ud83c\\udc04|[\\u2600-\\u26FF]|\\u2b05|\\u2b06|\\u2b07|\\u2b1b|\\u2b1c|\\u2b50|\\u2b55|\\u231a|\\u231b|' +
                    '\\u2328|\\u23cf|[\\u23e9-\\u23f3]|[\\u23f8-\\u23fa]|\\ud83c\\udccf|\\u2934|\\u2935|[\\u2190-\\u21ff])',
                'g'
            ),
            text
        );
    }

    public static compare(a: any, b: any) {
        try {
            if (
                (a === null && b !== null) ||
                (a !== null && b === null) ||
                (a === undefined && b !== undefined) ||
                (a !== undefined && b === undefined) ||
                typeof a !== typeof b ||
                (typeof a !== 'object' && a !== b)
            ) {
                return false;
            }

            // Check a's properties against b.
            for (const key in a) {
                if (!b.hasOwnProperty(key)) {
                    return false;
                }

                const type = typeof a[key];
                if (type === 'function') continue;
                else if (type === 'object') {
                    if (!Utilities.compare(a[key], b[key])) {
                        return false;
                    }
                } else if (a[key] !== b[key]) {
                    return false;
                }
            }

            // Check b for any extra properties.
            for (const key in b)
                if (typeof a[key] === 'undefined') {
                    return false;
                }

            return true;
        } catch (error) {
            return false;
        }
    }

    public static localiseList(strings: Array<TranslationKey>) {
        let text = strings
            .slice(0, strings.length - 1)
            .filter(notNullUndefinedEmptyOrZero)
            .map(t => ApplicationState.localise(t))
            .join(', ');
        const lastOne = strings[strings.length - 1];
        if (lastOne) {
            text += (text ? ` ${ApplicationState.localise('general.and')} ` : '') + ApplicationState.localise(lastOne);
        }
        return text as TranslationKey;
    }

    public static validateAndNotifyUI(test: boolean, message: TranslationKey, errors?: Array<ValidationErrorMessage>) {
        if (!errors) errors = new Array<ValidationErrorMessage>();
        if (!test) errors.push(new ValidationErrorMessage(message));
        return errors;
    }

    public static validateEmail(email: string) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    public static debounce(func: () => void, wait: number, immediate?: boolean): () => void {
        let timeout: any;
        return function (...args: any[]) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                if (!immediate) func.apply(this, args);
            }, wait);
            if (immediate && !timeout) func.apply(this, [...args]);
        };
    }

    public static async scrollTo(
        element: HTMLElement | null,
        to: number,
        duration: number,
        relative = false,
        easingFunction: (value: number, power: number) => number = easeInOut
    ) {
        if (!element) return;
        const from = element.scrollTop;
        if (from === to) return;
        to = relative ? from + to : to;
        ease(value => (element.scrollTop = value), easingFunction, duration, from, to);
    }

    public static toTitleCase(value: string): string {
        const updateParts = (value || '').split(' ');
        const update = updateParts.map(x => x.substring(0, 1).toLocaleUpperCase() + x.substring(1)).join(' ');
        return update;
    }

    public static getAvatarTextFromName(sourceName: string): string {
        let avatar = '';
        if (sourceName && sourceName.length) {
            let text = sourceName;
            const emojis = Utilities.findEmojis(sourceName);
            if (emojis.length) {
                return emojis[0];
            } else {
                text = Utilities.stripTitlesAndTrimName(text);
                const parts = text.trim().split(' ');
                avatar = parts[0].substring(0, 1);
                if (parts.length > 1) avatar += parts[parts.length - 1].substring(0, 1);
            }
        }
        return avatar.toUpperCase();
    }

    public static stripTitlesAndTrimName(name: string): string {
        const split = splitName(name);
        return `${split.firstname} ${split.lastname}`.replace(/[^a-z0-9 ]/gi, '').trim();
    }

    public static get manageAccountOnlineLink() {
        return (
            (GlobalFlags.isDevServer ? Api.currentHostAndScheme : 'https://app.squeeg.ee') +
            '/account?session-key=' +
            (<ISession>RethinkDbAuthClient.session).key +
            '&session-value=' +
            (<ISession>RethinkDbAuthClient.session).value
        );
    }

    public static arraysAreEqual(array1?: Array<number>, array2?: Array<number>) {
        if (array1 === undefined && array2 === undefined) return true;
        if (array1 === undefined || array2 === undefined) return false;
        if (array1?.length !== array2?.length) return false;
        for (let i = array1?.length || 0; i--; ) {
            if (array1[i] !== array2[i]) return false;
        }
        return true;
    }

    public static filterDuplicateStrings<T extends string>(array: Array<T>) {
        const seen: { [key: string]: true } = {};
        return array.filter(item => (seen.hasOwnProperty(item) ? false : (seen[item] = true)));
    }

    public static filterDuplicateNumbers(array: Array<number>) {
        const seen: { [key: number]: true } = {};
        return array.filter(item => (seen.hasOwnProperty(item) ? false : (seen[item] = true)));
    }

    public static parseFunction<TFunctionType extends (...args: any) => any>(functionAsString: string) {
        const bodyIndex = functionAsString.indexOf('{');
        const body = functionAsString.substring(bodyIndex + 1, functionAsString.lastIndexOf('}'));
        const declaration = functionAsString.substring(0, bodyIndex);
        const params = declaration.substring(declaration.indexOf('(') + 1, declaration.lastIndexOf(')'));
        const args = params.split(',');

        args.push(body);

        const functionGenerator = function () {
            return Function.apply(this, args);
        };

        functionGenerator.prototype = Function.prototype;
        const parsedFunction = <TFunctionType>(<any>functionGenerator());

        return parsedFunction;
    }

    public static chanceValue<TReturnType>(chance: number, someValue: TReturnType, otherwise: TReturnType): TReturnType {
        return Utilities.chance(chance) ? someValue : otherwise;
    }

    public static chance(chance: number) {
        return Math.random() < chance;
    }

    public static get coinFlip() {
        return Utilities.chance(0.5);
    }

    public static fixFrequencyData(frequencyData: FrequencyData) {
        if (frequencyData.firstScheduledDate?.length && frequencyData.firstScheduledDate.length > 10) {
            frequencyData.firstScheduledDate = frequencyData.firstScheduledDate.slice(0, 10);
        }

        const jobDate = moment(frequencyData.firstScheduledDate, 'YYYY-MM-DD');
        const date = jobDate.date();
        if (frequencyData.type === FrequencyType.DayOfMonth) {
            if (date !== frequencyData.dayOfMonth) frequencyData.dayOfMonth = date;
            if (frequencyData.dayOfWeek) delete frequencyData.dayOfWeek;
            if (frequencyData.weekOfMonth) delete frequencyData.weekOfMonth;
            return;
        }

        const weekday = jobDate.isoWeekday();
        if (frequencyData.type === FrequencyType.Weeks) {
            if (weekday !== frequencyData.dayOfWeek) frequencyData.dayOfWeek = weekday;
            if (frequencyData.dayOfMonth) delete frequencyData.dayOfMonth;
            if (frequencyData.weekOfMonth) delete frequencyData.weekOfMonth;
            return;
        }

        if (frequencyData.type === FrequencyType.MonthlyDayOfWeek) {
            if (FrequencyUtils.getWeekOfMonth(jobDate) !== frequencyData.weekOfMonth)
                frequencyData.weekOfMonth = FrequencyUtils.getWeekOfMonth(jobDate);
            if (weekday !== frequencyData.dayOfWeek) frequencyData.dayOfWeek = weekday;
            if (frequencyData.dayOfMonth) delete frequencyData.dayOfMonth;
            return;
        }
    }

    public static fixJobSchedule(job: Job, checkOnly = true) {
        let updated = false;
        if (job.date?.length && job.date.length > 10) {
            job.date = job.date.slice(0, 10);
            updated = true;
        }
        const jobDate = moment(job.date, 'YYYY-MM-DD');
        let updateText = '';

        const customer = Data.get(job.customerId);
        if (!customer) return;

        const jobRound = job.rounds[0] as JobGroup | undefined;
        const jobRoundDescription = jobRound?.description;
        const jobRoundDate = jobRound?.frequencyData?.firstScheduledDate;
        const jobRoundId = jobRound?._id;
        const round =
            (jobRoundId && Data.get<JobGroup>(jobRoundId)) ||
            Data.firstOrDefault<JobGroup>('tags', r => r.type === TagType.ROUND && r.description === jobRound?.description);
        const liveRoundDescription = round?.description;
        const liveRoundDate = round?.frequencyData?.firstScheduledDate;

        // Fix the out of date round
        if (round?.frequencyData && jobRoundDate !== liveRoundDate) {
            RoundService.setRoundAndFrequencyDataOnJob(job, round, round?.frequencyData, true);
            updated = true;
            updateText += `${job.location?.addressDescription} round date should be ${liveRoundDate} but is ${jobRoundDate}\n`;
        } else if (round?.frequencyData && jobRoundDescription !== liveRoundDescription) {
            RoundService.setRoundAndFrequencyDataOnJob(job, round, round?.frequencyData, true);
            updated = true;
            updateText += `${job.location?.addressDescription} round description should be ${liveRoundDescription} but is ${jobRoundDescription}\n`;
        } else {
            const date = jobDate.date();
            if (job.frequencyType === FrequencyType.DayOfMonth) {
                if (date !== job.frequencyDayOfMonth) {
                    if (!checkOnly) job.frequencyDayOfMonth = date;
                    else
                        updateText += `${job.location?.addressDescription} day of month should be ${date} but is ${job.frequencyDayOfMonth}\n`;
                    updated = true;
                }
                if (job.frequencyDayOfWeek) {
                    if (!checkOnly) delete job.frequencyDayOfWeek;
                    else updateText += `${job.location?.addressDescription} day of week should not be set for DayOfMonth schedules\n`;
                    updated = true;
                }
                if (job.frequencyWeekOfMonth) {
                    if (!checkOnly) delete job.frequencyWeekOfMonth;
                    else updateText += `${job.location?.addressDescription} week of month should not be set for DayOfMonth schedules\n`;
                    updated = true;
                }
            }

            const weekday = jobDate.isoWeekday();
            if (job.frequencyType === FrequencyType.Weeks) {
                if (weekday !== job.frequencyDayOfWeek) {
                    if (!checkOnly) job.frequencyDayOfWeek = weekday;
                    else
                        updateText += `${job.location?.addressDescription} day of week should be ${weekday} but is ${job.frequencyDayOfWeek}\n`;
                    updated = true;
                }
                if (job.frequencyDayOfMonth) {
                    if (!checkOnly) delete job.frequencyDayOfMonth;
                    else updateText += `${job.location?.addressDescription} day of month should not be set for Weeks schedules\n`;
                    updated = true;
                }
                if (job.frequencyWeekOfMonth) {
                    if (!checkOnly) delete job.frequencyWeekOfMonth;
                    else updateText += `${job.location?.addressDescription} week of month should not be set for Weeks schedules\n`;
                    updated = true;
                }
            }

            if (job.frequencyType === FrequencyType.MonthlyDayOfWeek) {
                if (FrequencyUtils.getWeekOfMonth(jobDate) !== job.frequencyWeekOfMonth) {
                    if (!checkOnly) job.frequencyWeekOfMonth = FrequencyUtils.getWeekOfMonth(jobDate);
                    else
                        updateText += `${job.location?.addressDescription} week of month should be ${FrequencyUtils.getWeekOfMonth(
                            jobDate
                        )} but is ${job.frequencyWeekOfMonth}\n`;
                    updated = true;
                }

                if (weekday !== job.frequencyDayOfWeek) {
                    if (!checkOnly) job.frequencyDayOfWeek = weekday;
                    else
                        updateText += `${job.location?.addressDescription} day of week should be ${weekday} but is ${job.frequencyDayOfWeek}\n`;
                    updated = true;
                }

                if (job.frequencyDayOfMonth) {
                    if (!checkOnly) delete job.frequencyDayOfMonth;
                    else
                        updateText += `${job.location?.addressDescription} day of month should not be set for MonthlyDayOfWeek schedules\n`;
                    updated = true;
                }
            }
        }

        if (checkOnly) console.log(updateText);

        return checkOnly ? false : updated;
    }

    public static copyObject = copyObject;

    /**
     * Warning should not be used on untrusted sources
     * @param html
     * @returns
     */
    public static extractTextFromHtml(html: string, formatWhitespace = true): string {
        try {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            const text = doc.body.textContent || '';

            //Removes duplicate new lines and replaces with only one
            return formatWhitespace ? text.replace(/\n\s*\n/g, '\n').trim() : text;
        } catch (error) {
            Logger.error('Unable to extract text from html');
            return '';
        }
    }

    public static async generateImageData(
        file: File,
        thumbnailBoundBox: Array<number>,
        imageAttachmentResolution: TImageAttachmentResolution,
        imageAttachmentQuality: TImageAttachmentQuality,
        createThumbnail = false
    ): Promise<IImageData> {
        const thumbnail = await new Promise<IImageThumbnailData>((resolve, reject) => {
            try {
                if (!thumbnailBoundBox || thumbnailBoundBox.length !== 2) {
                    throw 'You need to pass the thumbnailBoundBox';
                }
                const reader = new FileReader();
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    reader.onload = function (event: any) {
                        const img = new Image();
                        img.onload = function () {
                            try {
                                if (!createThumbnail) {
                                    return resolve({ dimensions: { x: img.naturalWidth, y: img.naturalHeight } });
                                }
                                const scaleRatio = Math.min(...thumbnailBoundBox) / Math.max(img.width, img.height);
                                const w = img.width * scaleRatio;
                                const h = img.height * scaleRatio;
                                canvas.width = w;
                                canvas.height = h;
                                ctx.drawImage(img, 0, 0, w, h);
                                return resolve({
                                    data: canvas.toDataURL(file.type, 0.75),
                                    dimensions: { x: img.naturalWidth, y: img.naturalHeight },
                                });
                            } catch (err) {
                                reject(err);
                            }
                        };
                        img.src = event.target.result as string;
                    };
                    reader.onerror = error => {
                        console.error('Error reading file', error);
                        reject(error);
                    };
                    reader.onabort = () => {
                        console.error('File reading aborted');
                        reject('File reading aborted');
                    };
                    reader.readAsDataURL(file);
                } else {
                    throw new Error('Unable generate Img Metadata could not create a canvas');
                }
            } catch (error) {
                reject(error);
            }
        });

        const image = await new Promise<File>((resolve, reject) => {
            try {
                const imageAttachmentResolutionOption = ImageAttachmentResolutionOptions[imageAttachmentResolution];
                const imageAttachmentQualityOption = ImageAttachmentQualityOptions[imageAttachmentQuality];
                const reader = new FileReader();
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    reader.onload = function (event: any) {
                        const img = new Image();
                        img.onload = function () {
                            try {
                                if (!imageAttachmentResolutionOption) return resolve(file);

                                const scaleRatio = Math.min(...imageAttachmentResolutionOption) / Math.max(img.width, img.height);
                                const w = img.width * scaleRatio;
                                const h = img.height * scaleRatio;
                                canvas.width = w;
                                canvas.height = h;
                                ctx.drawImage(img, 0, 0, w, h);
                                const imageDataUri = canvas.toDataURL(file.type, imageAttachmentQualityOption);
                                const imageBlob = Utilities.dataUriToBlob(imageDataUri);
                                return resolve(
                                    new NativeFile([imageBlob], file.name, { type: file.type, lastModified: file.lastModified })
                                );
                            } catch (err) {
                                reject(err);
                            }
                        };
                        img.src = event.target.result as string;
                    };
                    reader.onerror = error => {
                        console.error('Error reading file', error);
                        reject(error);
                    };
                    reader.onabort = () => {
                        console.error('File reading aborted');
                        reject('File reading aborted');
                    };
                    reader.readAsDataURL(file);
                } else {
                    throw new Error('Unable generate Img Metadata could not create a canvas');
                }
            } catch (error) {
                reject(error);
            }
        });

        return { image, thumbnail };
    }

    private static dataUriToBlob(dataUri: string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataUri.split(',')[1]);

        // separate out the mime component
        const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        const ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    /**
     * Formats the passed bytes into a readable string
     * @static
     * @param {number} bytes
     * @param {number} [decimals=2]
     * @return {*}  {string}
     */
    public static formatBytes(bytes: number, decimals = 2): string {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public static async copyToClipboard(text: string) {
        try {
            if (!navigator.clipboard || (GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isHttp))
                return Logger.warn('Clipboard api not available');

            if (typeof cordova !== 'undefined' && cordova?.plugins?.clipboard) return cordova.plugins.clipboard.copy(text);

            await navigator.clipboard.writeText(text);

            new NotifyUserMessage('clipboard.copy-success');
        } catch (err) {
            Logger.warn('Failed to copy link', err);
            new NotifyUserMessage('clipboard.copy-fail');
        }
    }

    public static durationToMinutes(duration?: string) {
        if (!duration) return 0;
        try {
            const durationParts = duration.split(':');
            const hours = Number(durationParts[0]);
            const minutes = Number(durationParts[1]);
            if (!isNaN(hours) && !isNaN(minutes)) return minutes + hours * 60;
        } catch (error) {
            return 0;
        }
        return 0;
    }
}

(window as any).utils = Utilities;
declare const utils: Record<string, any>;

export const animate = (delay?: number) =>
    new Promise<void>(resolve => {
        if (delay) wait(delay).then(() => requestAnimationFrame(() => resolve()));
        else requestAnimationFrame(() => resolve());
    });

export type Mutable<T> = { -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P] };

export const reduceById = <T extends StoredObject>(map: Record<string, T | undefined>, storedObject: T) => {
    if (!map) map = {};
    map[storedObject._id];
    return map;
};
utils.reduceById = reduceById;

export const validateEmailList = (value: string) =>
    value && value.split(',').some(value => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) ? 'validation.valid-email-required' : true;

export const deleteCookie = (key: string) => {
    document.cookie = key + '=; Max-Age=0';
};
utils.deleteCookie = deleteCookie;

export const getCookie = (key: string) => {
    function escape(text: string) {
        return text.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
    }
    const match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(key) + '=([^;]*)'));
    return match ? match[1] : undefined;
};
utils.getCookie = getCookie;

export const stripHtml = (html: string) => {
    return (html || '').replace(/(\s*<.*?>\s*)+/g, ' ').trim();
};
utils.stripHtml = stripHtml;

export const hasMobile = (number?: string) => {
    if (!number) return false;
    if (!ApplicationState.mobilePrefixes) return true;
    return ApplicationState.mobilePrefixes.some(prefix => number.startsWith(prefix));
};
utils.hasMobile = hasMobile;

export const openSystemBrowser = (url: string, features?: string, forceParentOnDesktop = false, name = '_blank') => {
    if (GlobalFlags.isMobileApp) {
        return window.open(url, '_parent', features);
    } else {
        return window.open(url, forceParentOnDesktop ? '_parent' : name, features);
    }
};

export const clearVisitedObjectSize = (value: any) => {
    if (typeof value === 'object' && value !== null && value['__visited__']) {
        delete value['__visited__'];
        for (const i in value) {
            clearVisitedObjectSize(value[i]);
        }
    }
};

export const roughSizeOfObject = (value: any, level = 0) => {
    if (level === undefined) level = 0;
    let bytes = 0;

    if (typeof value === 'boolean') {
        bytes = 4;
    } else if (typeof value === 'string') {
        bytes = value.length * 2;
    } else if (typeof value === 'number') {
        bytes = 8;
    } else if (typeof value === 'object' && value !== null) {
        if (value['__visited__']) return 0;
        value['__visited__'] = 1;
        for (const i in value) {
            bytes += i.length * 2;
            bytes += 8; // an assumed existence overhead
            bytes += roughSizeOfObject(value[i], 1);
        }
    }

    if (level == 0) clearVisitedObjectSize(value);

    return bytes;
};
utils.roughSizeOfObject = roughSizeOfObject;

export const accountSize = () => {
    const info = {} as Record<string, { bytes: number; text: string }>;
    for (const resourceType of StoredObjectResourceTypeNames.filter(x => x !== 'jobs')) {
        const data = Data.all(resourceType as any);
        if (!data.length) continue;

        const bytes = roughSizeOfObject(data);
        const text = Utilities.formatBytes(bytes);
        const item = { bytes, text };
        info[resourceType] = item;
    }

    const sortedInfo = Object.entries(info).sort(([, a], [, b]) => b.bytes - a.bytes);
    sortedInfo.forEach(([key, value]) => Logger.info(key, value.text));

    return info;
};
// define a getter on the window object so we can call it from the console
Object.defineProperty(utils, 'accountSize', { get: accountSize });

export const archiveSqueegeeCreditLedgerEntries = () => {
    const ledgerEntries = Data.all<SqueegeeCreditLedgerEntry>('squeegeecreditledgerentry').slice();
    for (const entry of ledgerEntries) {
        entry._deleted = true;
        entry._archived = true;
    }
    Data.put(ledgerEntries);
};

utils.archiveSqueegeeCreditLedgerEntries = archiveSqueegeeCreditLedgerEntries;

export const getAttachmentPublicUrl = (attachment: Attachment) => {
    return `${Api.currentHostAndScheme}/v/${attachment._id}`;
};

/**
 * JSON stringifies and then compares 2 objects.
 *
 * May not give the same value every time for the same input due to object key ordering.
 */
export const JSONCompare2Objects = (object1: Record<string, unknown>, object2: Record<string, unknown>) => {
    return JSON.stringify(object1) === JSON.stringify(object2);
};

export const safeParse = <Type, DefaultType>(json: string, defaultValue: DefaultType): Type | DefaultType => {
    try {
        return JSON.parse(json);
    } catch {
        return defaultValue;
    }
};

export type Digit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

export const unknownLengthBackgroundTask = (message: TranslationKey) => {
    let increment = 25;
    let percentageComplete = 0;
    const timer = setInterval(() => {
        try {
            increment = 5 * Math.exp(-percentageComplete / 75);
            percentageComplete += increment;

            if (percentageComplete >= 99) percentageComplete = 99;

            console.log('Task completion value', percentageComplete.toFixed(2));
            BGLoaderEvent.emit(true, t(message), Math.round(percentageComplete));
        } catch (error) {
            Logger.error('Error during background task progress reporter', error);
        }
    }, 500);

    return {
        done: () => {
            clearInterval(timer);
            setTimeout(() => BGLoaderEvent.emit(false, t(message), 100), 250);
        },
    };
};

export const logCustomerNotifications = (customerId?: string) => {
    customerId = customerId || ((window as any).sqtemp?.model?.customer?._id as string);
    if (!customerId) throw 'customerId is required, or you need to view a customer and press the "eye" button';

    Logger.info(`Customer notifications for ${customerId}`, Data.all<Notification>('notifications', { customerId }));
};

export const getDistanceInteger = (distance: number | undefined) => {
    return distance
        ? ApplicationState.distanceUnits === 'miles'
            ? `${(distance * 0.621371).toFixed()} mi`
            : `${distance?.toFixed()} km`
        : 'Unknown';
};

export const calculateFormattedTax = (
    price: number,
    customerId: string,
    account: Account
):
    | {
          subtotal: string;
          tax: string;
          total: string;
          taxConfig: TaxConfig;
      }
    | undefined => {
    const formatter = new Intl.NumberFormat(ApplicationState.account.language, {
        style: 'currency',
        currency: ApplicationState.currencyCode(),
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const customer = Data.get<Customer>(customerId);
    if (!customer) return undefined;

    const taxSettings = getTaxConfig(customer, account);
    if (!taxSettings)
        return {
            subtotal: '',
            tax: '',
            total: formatter.format(price),
            taxConfig: {
                taxEnabled: false,
                hideTax: false,
                priceIncludesTax: false,
                taxRate: 0,
            },
        };

    const { priceIncludesTax, taxEnabled, hideTax, taxRate } = taxSettings;
    if (!taxEnabled || hideTax || taxRate === undefined)
        return {
            subtotal: '',
            tax: '',
            total: formatter.format(price),
            taxConfig: taxSettings,
        };

    let tax = 0;
    let priceBeforeTax = 0;
    if (priceIncludesTax) {
        priceBeforeTax = price / (1 + taxRate);
        tax = price - priceBeforeTax;
    } else {
        priceBeforeTax = price;
        tax = price * taxRate;
        price += tax;
    }

    const formattedTax = formatter.format(Number(tax));
    const formattedSubtotal = formatter.format(Number(priceBeforeTax));
    const formattedTotal = formatter.format(Number(price));

    return {
        subtotal: formattedSubtotal,
        tax: formattedTax,
        total: formattedTotal,
        taxConfig: taxSettings,
    };
};

export const getStandardMessageTokensInUse = (text: string): Record<keyof StandardMessageModel, boolean> => {
    const tokensInUse =
        text?.match(/\[[^ ]*?]/g)?.reduce<Record<keyof StandardMessageModel, boolean>>((x, y) => {
            x[y.slice(1, -1) as keyof StandardMessageModel] = true;
            return x;
        }, {} as Record<keyof StandardMessageModel, boolean>) || ({} as Record<keyof StandardMessageModel, boolean>);

    return tokensInUse;
};
