import type { IFabAction } from '../Components/Fabs/IFabAction';
import { Data } from '../Data/Data';
import { FabWithActions } from '../FabWithActions';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { TeamFormDialog } from './TeamFormDialog';

export class Teams {
    protected newTeam = async () => {
        const dialog = new TeamFormDialog();
        const team = await dialog.show();

        if (dialog.cancelled) return;

        await Data.put(team).catch(error => {
            Logger.error('Error in newTeam() on Teams', { team, error });
            new NotifyUserMessage('users.save-error');
        });
    };

    attached() {
        FabWithActions.register(this.fabActions);
    }

    detached() {
        FabWithActions.unregister();
    }

    protected fabActions: Array<IFabAction> = [
        {
            tooltip: 'actions.add-new-team',
            actionType: 'action-new-customer',
            handler: this.newTeam,
            roles: ['Owner', 'Admin'],
        },
    ];
}
