import { ApplicationState } from '../../ApplicationState';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import './SelectedItemsDialog.scss';

export class SelectedItemsDialog extends CustomDialog<void> {
    constructor(protected readonly selectedItems: Array<ScheduleItem>) {
        super(
            'SelectedItemsDialog',
            '../VisualPlanning/View/SelectedItemsDialog.html',
            ApplicationState.localise('visual-planner.selected-jobs', { count: selectedItems.length.toString() }),
            {
                cssClass: 'selected-items-dialog',
                okLabel: '',
                cancelLabel: '',
                isSecondaryView: true,
            }
        );
    }

    public selectItem = async (scheduleItem: ScheduleItem) => {
        new ScheduleDetailsDialog(scheduleItem).show();
    };
}
