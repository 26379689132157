import type { Disposable } from 'aurelia-framework';
import { bindable, BindingEngine, inject } from 'aurelia-framework';
import type { UploadItem, UploadItemType } from '../UploadItem';
import { UploadManager } from '../UploadManager';
@inject(BindingEngine)
export class UploadingItems {
    @bindable type?: UploadItemType;
    protected uploads: Array<UploadItem>;
    constructor(protected bindingEngine: BindingEngine) { }
    private uploadsSub?: Disposable;
    attached() {
        this.uploadsSub = this.bindingEngine
            .collectionObserver(UploadManager.uploads)
            .subscribe(() => (this.uploads = UploadManager.getUploads(this.type)));
        this.uploads = UploadManager.getUploads(this.type);
    }

    detached() {
        this.uploadsSub?.dispose();
    }
}
