import type { AccountUser, TranslationKey } from '@nexdynamic/squeegee-common';
import { Team } from '@nexdynamic/squeegee-common';
import { observable } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Utilities } from '../Utilities';

export class TeamFormDialog extends CustomDialog<Team> {
    protected team: Team;
    private _isInit: boolean;
    protected hasAdvancedOrAbove: boolean = ApplicationState.hasAdvancedOrAbove;
    @observable() protected teamName = '';
    constructor(team?: Team) {
        super(
            'teamFormDialog',
            '../Users/TeamFormDialog.html',
            team ? ApplicationState.localise('teams.edit-team', { teamName: team.name }) : ApplicationState.localise('teams.new-team'),
            {
                cssClass: 'team-dialog',
                isSecondaryView: true,
                okLabel: '',
                cancelLabel: '',
            }
        );
        if (!team) team = new Team('');
        this.team = Utilities.copyObject(team);
        this._isInit = true;
        this.isTemporaryTeam = !!this.team.activeTo || !!this.team.activeFrom;
        this._isInit = false;
        this.teamName = team.name;
    }

    protected delegateOk = () => {
        this.team.name = this.teamName;
        const teamName = this.team.name.trim();
        if (teamName) this.ok();
        else {
            new NotifyUserMessage('teams.name-required');
        }
    };

    async getResult() {
        return this.team;
    }

    teamNameChanged(newValue: string) {
        if (!newValue || newValue.length === 0) {
            this.teamName = this._autoTeamName;
        }
    }

    private _autoTeamName = '';
    protected updateMembers(selectedMembers: Array<AccountUser>) {
        const updateTeamName = this.teamName.length === 0 || !!(this._autoTeamName && this.teamName === this._autoTeamName);
        this.team.members = selectedMembers.map(memeber => memeber._id);
        this._autoTeamName = selectedMembers.map(m => Utilities.getAvatarTextFromName(m.name)).join(', ');
        if (updateTeamName) {
            this.teamName = this._autoTeamName;
        }
    }

    @observable() protected isTemporaryTeam: boolean;

    isTemporaryTeamChanged(newValue: boolean) {
        if (this._isInit) return;
        if (newValue) {
            this.team.activeFrom = moment().format('YYYY-MM-DD');
            this.team.activeTo = moment().format('YYYY-MM-DD');
        } else {
            delete this.team.activeFrom;
            delete this.team.activeTo;
        }
    }

    async editFromDate() {
        const datePickerDialog = new DateTimePicker(false, this.team.activeFrom);
        datePickerDialog.init();
        datePickerDialog.title = 'Team active from' as TranslationKey;
        await datePickerDialog.open();
        if (datePickerDialog.canceled) return;
        this.team.activeFrom = datePickerDialog.selectedDate;
    }

    async editToDate() {
        const datePickerDialog = new DateTimePicker(false, this.team.activeTo);
        datePickerDialog.init();
        datePickerDialog.title = 'Team active to' as TranslationKey;
        await datePickerDialog.open();
        if (datePickerDialog.canceled) return;
        this.team.activeTo = datePickerDialog.selectedDate;
    }
}
