// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-limit__title {
  font-size: 18px;
  font-weight: 500;
}
.users-limit__footer {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Users/Components/users-limit.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,gBAAA;AAAR;AAEI;EACI,gBAAA;AAAR","sourcesContent":[".users-limit {\n    &__title {\n        font-size: 18px;\n        font-weight: 500;\n    }\n    &__footer {\n        font-weight: 500;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
