// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-items-dialog context-menu-bar {
  background-color: var(--themed-main-dark);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/VisualPlanning/View/SelectedItemsDialog.scss"],"names":[],"mappings":"AAGI;EACI,yCAAA;EACA,WAAA;AAFR","sourcesContent":["@import '../../Theme.scss';\n\n.selected-items-dialog {\n    context-menu-bar {\n        background-color: $main-dark;\n        color: #fff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
