// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visual-planner .map-fixed {
  height: calc(var(--max-view-height) - 112px);
}
.visual-planner .selected-list {
  position: fixed;
  right: 0;
  bottom: 0;
  height: calc(var(--max-view-height) - 64px);
  width: 380px;
  z-index: 1;
  background-color: #fff;
  padding: 16px;
}
.visual-planner .search-bar {
  position: relative !important;
}
.visual-planner .search-bar.search-hidden {
  background-color: var(--themed-main-darker) !important;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/VisualPlanning/View/VisualPlanner.scss"],"names":[],"mappings":"AAGI;EACI,4CAAA;AAFR;AAII;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,2CAAA;EACA,YAAA;EACA,UAAA;EACA,sBAAA;EACA,aAAA;AAFR;AAII;EACI,6BAAA;AAFR;AAGQ;EACI,sDAAA;EACA,WAAA;AADZ","sourcesContent":["@import '../../Theme.scss';\n\n.visual-planner {\n    .map-fixed {\n        height: calc(var(--max-view-height) - 112px);\n    }\n    .selected-list {\n        position: fixed;\n        right: 0;\n        bottom: 0;\n        height: calc(var(--max-view-height) - 64px);\n        width: 380px;\n        z-index: 1;\n        background-color: #fff;\n        padding: 16px;\n    }\n    .search-bar {\n        position: relative !important;\n        &.search-hidden {\n            background-color: $main-darker !important;\n            color: #fff;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
