import type { AccountUser, Customer, Job, JobOccurrence, StoredObject, Team } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import type { DataChangeNotificationType } from '../../Data/DataChangeNotificationType';
import { JobService } from '../../Jobs/JobService';
import { UserAuthorisationService } from '../UserAuthorisationService';
import { UserService } from '../UserService';
import { AssignmentService } from './AssignmentService';
type LegacyJob = Job & { assignedTo?: string };

type LegacyJobOccurrence = JobOccurrence & { assignedTo?: string };

type JobOrJobOccurrence = LegacyJob | LegacyJobOccurrence;
export class AssigmentLegacyService {
    isAssignedToTeam(jobOrJobOccurrence: JobOrJobOccurrence) {
        // legacy
        if (!jobOrJobOccurrence.assignedTo) return false;
        const team = Data.get(jobOrJobOccurrence.assignedTo);
        if (!team) return false;
        if (team.resourceType !== 'team') return false;
        return true;
    }

    isUnassigned(jobOrJobOccurrence: JobOrJobOccurrence): boolean {
        if (!jobOrJobOccurrence.assignedTo) return true;
        const userOrTeam = this._getUserOrTeamFromLegacyId(jobOrJobOccurrence.assignedTo);
        if (!userOrTeam) return true;
        if (userOrTeam.resourceType === 'accountuser') {
            const user = userOrTeam as AccountUser;
            // Owner cant be deactivated
            if (user.email !== ApplicationState.dataEmail) {
                const auth = UserAuthorisationService.getUserAuthorisation(user.email);
                if (!auth || auth.deactivated) return true;
            }
        }
        return false;
    }
    isAssignedToUserEmail(
        jobOrOccurrence: JobOrJobOccurrence,
        email: string,
        includeWhereAssignedAsTeamMembers: boolean
    ): boolean | Team[] {
        if (!jobOrOccurrence.assignedTo) return false;
        if (jobOrOccurrence.assignedTo === email) return true;

        if (includeWhereAssignedAsTeamMembers) {
            const team = Data.get<Team>(jobOrOccurrence.assignedTo);
            if (team && team.resourceType === 'team') {
                const teamMembers = team.members || [];
                if (teamMembers.includes(email)) return [team];
            }
        }
        return false;
    }

    isAssigned(
        jobOrOccurrence: JobOrJobOccurrence,
        userOrTeamIds: string | string[],
        includeWhereAssignedAsTeamMembers: boolean
    ): undefined | boolean {
        const userOrTeamId = Array.isArray(userOrTeamIds) ? userOrTeamIds[0] : userOrTeamIds;

        if (!jobOrOccurrence.assignedTo) return undefined;
        if (jobOrOccurrence.assignedTo === userOrTeamId) return true;

        const userOrTeamToFilterWith: StoredObject | undefined = Data.get(userOrTeamId);
        if (!userOrTeamToFilterWith) return false;

        const userAssignedToByEmail = UserService.getUser(jobOrOccurrence.assignedTo);

        if (userAssignedToByEmail) {
            // Assigned to a user by email address;
            if (userOrTeamToFilterWith.resourceType === 'accountuser') {
                const user = userOrTeamToFilterWith as AccountUser;
                if (jobOrOccurrence.assignedTo === user.email) return true;
                return false;
            } else if (userOrTeamToFilterWith.resourceType === 'team' && includeWhereAssignedAsTeamMembers) {
                const team = userOrTeamToFilterWith as Team;
                return team.members.includes(userAssignedToByEmail._id) || team.members.includes(jobOrOccurrence.assignedTo);
            } else {
                return false;
            }
        } else {
            // Assigned to a team or a new user id;
            const userOrTeamAssignedTo = Data.get(jobOrOccurrence.assignedTo);
            if (!userOrTeamAssignedTo) return undefined;

            if (userOrTeamAssignedTo.resourceType === 'team') {
                // Assigned to a team
                const team = userOrTeamAssignedTo as Team;

                if (userOrTeamToFilterWith.resourceType === 'team') {
                    if (team._id === userOrTeamToFilterWith._id) return true;
                    return false;
                } else {
                    // filter with a user
                    if (team.members.includes(userOrTeamToFilterWith._id)) return true;
                    return false;
                }
            } else {
                // Asigned to a user using user id;
                const user = userOrTeamAssignedTo as AccountUser;
                if (user._id === userOrTeamAssignedTo._id) return true;
                return false;
            }
        }
    }

    getAssignees(jobOrJobOccurrence: JobOrJobOccurrence, _resolveUsers?: boolean | undefined): (Team | AccountUser)[] {
        if (!ApplicationState.multiUserEnabled) return [];
        const assignee: string | undefined = jobOrJobOccurrence.assignedTo;
        if (!assignee) return [];
        const userOrTeam: AccountUser | Team | undefined = this._getUserOrTeamFromLegacyId(assignee);
        if (!userOrTeam) return [];
        return [userOrTeam];
    }

    getAssigneeIds(jobOrJobOccurrence: JobOrJobOccurrence): string[] {
        const assignees = this.getAssignees(jobOrJobOccurrence, false);
        return assignees.map(a => a._id);
    }
    getDefaultAssignedTo(): string {
        return AssignmentService.getDefaultAssigneeUserOrTeam()?._id || '';
    }
    async assign(
        jobOrOccurrence: JobOrJobOccurrence,
        userOrTeamIds: string | string[] | 'UNASSIGNED',
        alsoUpdateJobs: boolean,
        isBulk: boolean
    ) {
        const userOrTeamId = Array.isArray(userOrTeamIds) ? userOrTeamIds[0] : userOrTeamIds;
        if (!userOrTeamId) return false;
        // Passed in an unassign request so go ahead and unassign
        if (userOrTeamId === 'UNASSIGNED') return this.unassign(jobOrOccurrence, userOrTeamId, alsoUpdateJobs, isBulk);

        // Are we handling a job occurrence or a Job?
        if (jobOrOccurrence.resourceType === 'joboccurrences') {
            // Did the user confirm they want the job to be assigned to the user?
            if (alsoUpdateJobs) {
                const job = this._getJobFromJobOrOccurrence(jobOrOccurrence as LegacyJobOccurrence);
                if (job) {
                    await this.assignJob(job, userOrTeamId, true, isBulk);
                }
            }
            this.assignJobOccurrence(jobOrOccurrence as LegacyJobOccurrence, userOrTeamId, isBulk);
        } else {
            // This is a job so go and assign it
            this.assignJob(jobOrOccurrence as LegacyJob, userOrTeamId, alsoUpdateJobs, isBulk);
        }
    }

    async assignJobOccurrence(jobOccurrence: LegacyJobOccurrence, userOrTeamId: string, isBulk: boolean) {
        const legacyUserEmailOrTeamId = this._getUserEmailFromId(userOrTeamId);
        jobOccurrence.assignedTo = legacyUserEmailOrTeamId;

        const notify: DataChangeNotificationType = isBulk ? 'lazy' : 'immediate';

        await Data.put(jobOccurrence, undefined, notify);
    }

    async assignJob(job: LegacyJob, userOrTeamId: string, alsoUpdateNotDoneOccurrences = false, isBulk: boolean) {
        const legacyUserEmailOrTeamId = this._getUserEmailFromId(userOrTeamId);
        job.assignedTo = legacyUserEmailOrTeamId;
        await JobService.addOrUpdateJob(
            job.customerId,
            job,
            undefined,
            undefined,
            isBulk,
            true,
            alsoUpdateNotDoneOccurrences ? ['assignedTo'] : undefined,
            undefined,
            undefined,
            alsoUpdateNotDoneOccurrences ? [userOrTeamId] : undefined
        );
    }

    async unassign(jobOrOccurrence: JobOrJobOccurrence, userOrTeamId: string, alsoUpdateJobs: boolean, isBulk: boolean): Promise<boolean> {
        // Are we handling a job occurrence or a Job?
        if (jobOrOccurrence.resourceType === 'joboccurrences') {
            // Did the user confirm they want the job to be assigned to the user?
            if (alsoUpdateJobs) {
                const job = this._getJobFromJobOrOccurrence(jobOrOccurrence as LegacyJobOccurrence);
                if (job) {
                    await this.unassignJob(job, true, isBulk);
                }
            }
            this.unassignJobOccurrence(jobOrOccurrence as LegacyJobOccurrence, userOrTeamId, isBulk);
        } else {
            // This is a job so go and assign it
            this.unassignJob(jobOrOccurrence as LegacyJob, alsoUpdateJobs, isBulk);
        }

        return true;
    }

    async unassignJobOccurrence(jobOccurrence: LegacyJobOccurrence, _userOrTeamId: string, isBulk: boolean) {
        delete jobOccurrence.assignedTo;
        const notify: DataChangeNotificationType = isBulk ? 'lazy' : 'immediate';

        await Data.put(jobOccurrence, undefined, notify);
    }

    async unassignJob(job: LegacyJob, alsoUpdateNotDoneOccurrences = false, isBulk: boolean) {
        if (!job.assignedTo) return;
        delete job.assignedTo;

        await JobService.addOrUpdateJob(
            job.customerId,
            job,
            undefined,
            undefined,
            isBulk,
            true,
            alsoUpdateNotDoneOccurrences ? ['assignedTo'] : undefined,
            undefined,
            undefined,
            alsoUpdateNotDoneOccurrences ? [] : undefined
        );
    }

    private _getUserEmailFromId(emailOrId: string): string {
        if (emailOrId.includes('@')) return emailOrId;

        const userOrTeam = Data.get<AccountUser>(emailOrId);
        if (userOrTeam?.email) {
            return userOrTeam.email;
        } else {
            return emailOrId;
        }
    }

    private _getUserOrTeamFromLegacyId(id: string) {
        if (!id) return undefined;
        let userOrTeam: AccountUser | Team | undefined;
        const user = UserService.getUser(id);
        if (user) {
            userOrTeam = user;
        } else {
            const team = Data.get<Team>(id);
            if (team) {
                userOrTeam = team;
            }
        }
        return userOrTeam;
    }
    private _getJobFromJobOrOccurrence(jobOrOccurrence: JobOccurrence): LegacyJob | undefined {
        if (!jobOrOccurrence.customerId || !jobOrOccurrence.jobId) return;
        const cust = Data.get<Customer>(jobOrOccurrence.customerId);
        if (!cust) return;
        return cust.jobs[jobOrOccurrence.jobId];
    }
}

const AssignmentLegacyServiceSingleton = new AssigmentLegacyService();

export default AssignmentLegacyServiceSingleton;
