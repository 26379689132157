import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { Alert } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Data } from './Data/Data';

export const generateStartupAlerts = () => {
    const priceChangesTomorrow = Data.all<BulkPriceChange>(
        'bulkpricechange',
        x => !x.result && !!x.plannedDate && moment(x.plannedDate).isSame(moment().add(1, 'day'), 'day')
    );
    if (priceChangesTomorrow.length) {
        for (const priceChange of priceChangesTomorrow) {
            if (Data.get<Alert>(Alert.priceChangeAlertId(priceChange, 'tomorrow'))) return;
            const alert = Alert.createPriceChangeNeedsApplyingTomorrowAlert(priceChange);
            Data.put(alert);
        }
    }

    const priceChangesToday = Data.all<BulkPriceChange>(
        'bulkpricechange',
        x => !x.result && !!x.plannedDate && moment(x.plannedDate).isSame(moment(), 'day')
    );
    if (priceChangesToday.length) {
        for (const priceChange of priceChangesToday) {
            if (Data.get<Alert>(Alert.priceChangeAlertId(priceChange, 'today'))) return;
            const alert = Alert.createPriceChangeNeedsApplyingTodayAlert(priceChange);
            Data.put(alert);
        }
    }
};
