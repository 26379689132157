import type { AccountUser, Team } from '@nexdynamic/squeegee-common';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { Logger } from '../Logger';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { UserService } from './UserService';
import { Workload } from './Workload';

export class SelectWorkloadDialog extends CustomDialog<Workload | undefined> {
    public filteredList: Array<Workload>;
    public searchTerm = '';

    protected total: number;

    constructor(
        private _scheduleItems: Array<ScheduleItem>,
        private _includeUnassigned = true,
        private _includeSpareCapacity = true,
        private _includeAll = false,
        private _hideEmpty = false,
        public selectedWorkload?: Workload,
        public date?: string
    ) {
        super('selectWorkloadDialog', '../Users/SelectWorkloadDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'select-list-dialog select-dialog-with-search',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });
        this._init();
        this.total = _scheduleItems.length;
    }

    private _list: Array<Workload> = [];

    protected _delegateOk() {
        this.ok(this.selectedWorkload);
    }

    public async load() {
        try {
            const users = await UserService.getWorkers();

            const teams = UserService.getTeams(this.date);
            const sortedScheduleItemsByIsoDueDate = this._scheduleItems.sort((a, b) =>
                a.occurrence.isoDueDate?.localeCompare(b.occurrence.isoDueDate)
            );
            const oldestScheduleItemByIsoDueDate = sortedScheduleItemsByIsoDueDate[0];
            const newestScheduleItemByIsoDueDate = sortedScheduleItemsByIsoDueDate[sortedScheduleItemsByIsoDueDate.length - 1];

            const availableTeams = new Array<Team>();
            for (const team of teams) {
                if (!team.activeFrom && !team.activeTo) {
                    availableTeams.push(team);
                    continue;
                }
                if (
                    team.activeFrom &&
                    team.activeTo &&
                    team.activeFrom <= oldestScheduleItemByIsoDueDate.occurrence.isoDueDate &&
                    team.activeTo >= newestScheduleItemByIsoDueDate.occurrence.isoDueDate
                ) {
                    availableTeams.push(team);
                }
            }

            this._list = Workload.getWorkloadsForUsers(
                users,
                availableTeams,
                this._scheduleItems,
                this._includeUnassigned,
                this._includeSpareCapacity,
                this._includeAll,
                this._hideEmpty
            );

            this.filter();

            new ViewResizeEvent();
        } catch (error) {
            Logger.error('Error in load() on SelectWorkloadDialog', error);
        }
    }

    public filter() {
        this.filteredList = this._list.filter(item => {
            if (!this.searchTerm) return true;

            const searchTerm = this.searchTerm.trim().toLowerCase();
            if (item.userOrTeam) {
                const email = (<AccountUser>item.userOrTeam).email && (<AccountUser>item.userOrTeam).email;
                if (email && email.toLowerCase().indexOf(searchTerm) > -1) return true;

                if (item.userOrTeam.name && item.userOrTeam.name.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1)
                    return true;
            }

            return false;
        });
    }

    private _init() {
        this.load();
    }

    protected selectUser(workload: Workload) {
        this.selectedWorkload = workload;
        this.ok();
    }

    public async getResult() {
        return this.selectedWorkload;
    }
}
