import { Logger } from '../Logger';
import type { UploadItem, UploadItemType } from './UploadItem';

export class UploadManager {
    public static uploads: Array<UploadItem> = [];

    static add(item: UploadItem) {
        item.upload().catch(error => Logger.error('Error uploading item in queue', error));
        UploadManager.uploads.push(item);
    }

    static remove(item: UploadItem) {
        const indexToRemove = UploadManager.uploads.indexOf(item);
        if (indexToRemove > -1) UploadManager.uploads.splice(indexToRemove, 1);
    }

    static getUploads(type?: UploadItemType) {
        return type ? UploadManager.uploads.filter(item => item.type === type) : UploadManager.uploads.slice();
    }
}
