import type {
    AccountUser,
    Customer,
    Job,
    JobOccurrence,
    Quote,
    StoredObject,
    Transaction,
    TranslationKey
} from '@nexdynamic/squeegee-common';
import {
    TransactionType
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { CustomerDialog } from '../Customers/Components/CustomerDialog';
import { JobSummaryDialog } from '../Customers/Components/JobSummaryDialog';
import { Data } from '../Data/Data';
import type { CustomDialog } from '../Dialogs/CustomDialog';
import { Prompt } from '../Dialogs/Prompt';
import { ExpenseSummaryDialog } from '../Expenses/Components/ExpenseSummaryDialog';
import { InvoiceTransactionSummaryDialog } from '../Invoices/InvoiceTransactionSummaryDialog';
import { QuoteSummaryDialog } from '../Quotes/Dialogs/QuoteSummaryDialog';
import { ScheduleDetailsDialog } from '../Schedule/Components/ScheduleDetailsDialog';
import { ScheduleService } from '../Schedule/ScheduleService';
import { TransactionDialog } from '../Transactions/Components/TransactionDialog';
import { UserDialog } from '../Users/UserDialog';

export interface IStoredObjectModel {
    name: string;
    title: string;
    description: string;
    icon: string;
    getDialog?: () => CustomDialog<any>;
    showDialog?: () => Promise<any>;
}

export default class StoredObjectUtils {
    private static customerModel(customer: Customer): IStoredObjectModel {
        return {
            name: ApplicationState.localise('general.customer'),
            title: customer.name,
            description: customer.address.addressDescription || '',
            icon: 'person',
            showDialog: () => new CustomerDialog(customer).show(),
        };
    }

    private static jobOccurrencesModel(jobOccurrence: JobOccurrence): IStoredObjectModel {
        const scheduleItem = ScheduleService.getScheduleItemForJobOccurrence(jobOccurrence);

        return {
            name: ApplicationState.localise('general.appointment'),
            title: `${ApplicationState.localise('general.job')} ${moment(jobOccurrence.isoDueDate).format('ddd, MMM Do, YYYY')}`,
            description: jobOccurrence.location?.addressDescription || '',
            icon: 'event',
            showDialog: scheduleItem ? () => new ScheduleDetailsDialog(scheduleItem).show() : undefined,
        };
    }

    private static jobModel(job: Job): IStoredObjectModel {
        const customer = Data.get<Customer>(job.customerId);

        return {
            name: ApplicationState.localise('general.job'),
            title: ApplicationState.localise('general.job'),
            description: job.location?.addressDescription || '',
            icon: 'event',
            showDialog: customer ? () => new JobSummaryDialog(customer, job).show() : undefined,
        };
    }

    private static accountUserModel(user: AccountUser): IStoredObjectModel {
        return {
            name: ApplicationState.localise('general.user'),
            title: user.name,
            description: user.email,
            showDialog: () => new UserDialog(user, false).show(),
            icon: 'account_circle',
        };
    }

    private static invoiceModel(transaction: Transaction): IStoredObjectModel {
        return {
            name: ApplicationState.localise('general.invoice'),
            getDialog: () => new InvoiceTransactionSummaryDialog(transaction),
            showDialog: () => new InvoiceTransactionSummaryDialog(transaction).show(),
            icon: 'description',
            title: ApplicationState.localise('general.invoice'),
            description: transaction.invoice
                ? `${transaction.invoice.invoiceNumber ? `#${transaction.invoice.invoiceNumber} ` : ''}${transaction.invoice.billTo}`.trim()
                : transaction.description || '',
        };
    }

    private static expenseModel(transaction: Transaction): IStoredObjectModel {
        return {
            name: ApplicationState.localise('general.expense'),
            icon: 'receipt',
            title: ApplicationState.localise('general.expense'),
            description: transaction.description || '',
            showDialog: () => new ExpenseSummaryDialog(transaction._id).show(),
            getDialog: () => new ExpenseSummaryDialog(transaction._id)
        };
    }

    private static quoteModel(quote: Quote): IStoredObjectModel {
        console.log(quote);
        const customer = Data.get<Customer>(quote.customerId);
        return {
            name: ApplicationState.localise('general.quote'),
            icon: 'request_quote',
            title: ApplicationState.localise('general.quote'),
            description: `${customer?.name ? `${customer?.name} - ` : ''}${customer?.address?.addressDescription || ''}`,
            showDialog: () => new QuoteSummaryDialog(quote).show(),
        };
    }

    private static transactionModel(transaction: Transaction): IStoredObjectModel {
        switch (transaction.transactionType) {
            case TransactionType.Invoice:
                return StoredObjectUtils.invoiceModel(transaction);
            case TransactionType.Expense:
                return StoredObjectUtils.expenseModel(transaction);
            default:
                return {
                    name: ApplicationState.localise('general.transaction'),
                    icon: 'receipt',
                    title: ApplicationState.localise('general.transaction'),
                    description: transaction.description || '',
                    showDialog: () => new TransactionDialog(transaction).show(),
                    getDialog: () => new TransactionDialog(transaction)
                }
        }
    }


    private static unknownModel(item: StoredObject): IStoredObjectModel {
        return {
            name: item.resourceType,
            title: 'Unknown Item',
            description: 'This item does not have a resource type',
            icon: '',
            showDialog: () =>
                new Prompt(
                    'Unknown' as TranslationKey,
                    'You are unable to view this item as its type is unknown.' as TranslationKey
                ).show(),
        };
    }

    public static getUIModelForStoredObject(item: StoredObject): IStoredObjectModel {
        switch (item.resourceType) {
            case 'customers':
                return StoredObjectUtils.customerModel(item as Customer);
            case 'joboccurrences':
                return StoredObjectUtils.jobOccurrencesModel(item as JobOccurrence);
            case '':
            case 'jobs':
                return StoredObjectUtils.jobModel(item as Job);
            case 'accountuser':
                return StoredObjectUtils.accountUserModel(item as AccountUser);
            case 'transactions':
                return StoredObjectUtils.transactionModel(item as Transaction);
            case 'quotes':
                return StoredObjectUtils.quoteModel(item as Quote);
            default:
                return StoredObjectUtils.unknownModel(item);
        }
    }

    public static getDialogForStoredObject(item: StoredObject): IStoredObjectModel {
        switch (item.resourceType) {
            case 'customers':
                return StoredObjectUtils.customerModel(item as Customer);
            case 'joboccurrences':
                return StoredObjectUtils.jobOccurrencesModel(item as JobOccurrence);
            case '':
            case 'jobs':
                return StoredObjectUtils.jobModel(item as Job);
            case 'accountuser':
                return StoredObjectUtils.accountUserModel(item as AccountUser);
            case 'transactions':
                return StoredObjectUtils.transactionModel(item as Transaction);
            case 'quotes':
                return StoredObjectUtils.quoteModel(item as Quote);
            default:
                return StoredObjectUtils.unknownModel(item);
        }
    }
}
