import type { JobOccurrence, LngLat, Team } from '@nexdynamic/squeegee-common';
import { AccountUser, AlphanumericCharColourDictionary, CapacityMeasurementType, JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { TrackingService } from '../Tracking/TrackingService';
import { AssignmentService } from './Assignees/AssignmentService';

export class Workload {
    public done: number;
    public doneRelative: number;
    public skipped: number;
    public skippedRelative: number;
    public notDone: number;
    public notDoneRelative: number;
    public series: Array<number>;
    public selected = false;

    constructor(public userOrTeam: AccountUser | Team | undefined, occurrences: Array<JobOccurrence>, public spareCapacity: number = 0) {
        this.totalUpOccurrence(occurrences);
        this.createSeries();
    }

    private totalUpOccurrence(occurrences: Array<JobOccurrence>): void {
        this.done = 0;
        this.doneRelative = 0;
        this.skipped = 0;
        this.skippedRelative = 0;
        this.notDone = 0;
        this.notDoneRelative = 0;
        for (const occurrence of occurrences) {
            const byPrice = ApplicationState.workloadCapacityMeasurementType === CapacityMeasurementType.VALUE_OF_JOBS;
            const increment = byPrice ? occurrence.price : 1;
            switch (occurrence.status) {
                case JobOccurrenceStatus.NotDone:
                    this.notDone += 1;
                    this.notDoneRelative += increment;
                    break;
                case JobOccurrenceStatus.Skipped:
                    this.skipped += 1;
                    this.skippedRelative += increment;
                    break;
                case JobOccurrenceStatus.Done:
                    this.done += 1;
                    this.doneRelative += increment;
                    break;
            }
        }
    }

    private createSeries() {
        const spareCapacity = this.spareCapacity
            ? this.spareCapacity - (this.doneRelative + this.skippedRelative + this.notDoneRelative)
            : 0;
        this.series = [
            this.doneRelative,
            this.skippedRelative,
            this.notDoneRelative,
            //TODO Some work hour function
            spareCapacity > 0 ? spareCapacity : 0,
        ];
    }

    public updateSeries(series: Array<number>) {
        this.series = series;
    }

    private static _colours = new AlphanumericCharColourDictionary();
    public get avatarColor() {
        return Workload._colours[this.userOrTeam ? this.userOrTeam.name.substring(0, 1).toUpperCase() : '?'];
    }

    public static getWorkloadsForUsers(
        users: Array<AccountUser>,
        teams: Array<Team>,
        scheduleItems: Array<ScheduleItem>,
        includeUnassigned = true,
        includeSpareCapacity = true,
        includeAll = false,
        hideEmpty = false,
        selectedId?: string
    ): Array<Workload> {
        const workloads: Array<Workload> = [];
        if (includeAll) {
            const all = scheduleItems.map(item => item.occurrence);

            const workload = new Workload(
                <any>{ name: ApplicationState.localise('general.all') },
                all,
                includeSpareCapacity ? ApplicationState.workloadCapacityAmount : 0
            );
            if (selectedId === undefined) workload.selected = true;
            workloads.push(workload);
        }
        if (includeUnassigned) {
            const defaultAssignee = AssignmentService.getDefaultAssigneeUserOrTeam();
            if (defaultAssignee) {
                const defaultUser = new AccountUser('');
                defaultUser._id = 'UNASSIGNED';
                defaultUser.name = `Default Assignee (Currently: ${defaultAssignee.name})`;

                const defaultAssigned = scheduleItems
                    .filter(item => {
                        return AssignmentService.isUnassigned(item.occurrence);
                    })
                    .map(item => item.occurrence);
                const workload = new Workload(
                    defaultUser,
                    defaultAssigned,
                    includeSpareCapacity ? users.length * ApplicationState.workloadCapacityAmount : 0
                );
                if (selectedId === 'UNASSIGNED') workload.selected = true;
                workloads.push(workload);
            } else {
                const unassigned = scheduleItems
                    .filter(item => {
                        return AssignmentService.isUnassigned(item.occurrence);
                    })
                    .map(item => item.occurrence);

                const user = new AccountUser('');
                user._id = 'UNASSIGNED';
                user.name = ApplicationState.localise('general.unassigned');

                const workload = new Workload(
                    user,
                    unassigned,
                    includeSpareCapacity ? users.length * ApplicationState.workloadCapacityAmount : 0
                );
                if (selectedId === 'UNASSIGNED') workload.selected = true;
                workloads.push(workload);
            }
        }

        for (const t of teams) {
            const teamOccurrences = scheduleItems
                .filter(item => AssignmentService.isAssigned(item.occurrence, t._id, false))
                .map(item => item.occurrence);

            if (!hideEmpty || (hideEmpty && teamOccurrences.length)) {
                const teamCapacity = ((t.members && t.members.length) || 0) * ApplicationState.workloadCapacityAmount;
                const workload = new Workload(t, teamOccurrences, includeSpareCapacity ? teamCapacity : 0);
                if (workload.userOrTeam?._id === selectedId) workload.selected = true;
                workloads.push(workload);
            }
        }

        for (const user of users) {
            const userOccurrences = scheduleItems
                .filter(item => AssignmentService.isAssigned(item.occurrence, user._id, true))
                .map(item => item.occurrence);
            if (!hideEmpty || (hideEmpty && userOccurrences.length)) {
                const workload = new Workload(user, userOccurrences, includeSpareCapacity ? ApplicationState.workloadCapacityAmount : 0);
                if (workload.userOrTeam?._id === selectedId) workload.selected = true;
                workloads.push(workload);
            }
        }

        return workloads;
    }

    getLngLat(): LngLat | undefined {
        if (!this.userOrTeam?._id) return;
        return TrackingService.getLatestTracking(this.userOrTeam)?.lngLat;
    }
}
