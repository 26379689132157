// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.device-audit-footer {
  white-space: pre-line;
}

.device-audit-tag {
  display: inline-block;
  padding: 0 6px;
  border-radius: 3px;
  margin: 0 4px 4px 0;
  background-color: #008fe1;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/Users/Components/device-list-item.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;AACJ","sourcesContent":[".device-audit-footer {\n    white-space: pre-line;\n}\n\n.device-audit-tag {\n    display: inline-block;\n    padding: 0 6px;\n    border-radius: 3px;\n    margin: 0 4px 4px 0;\n    background-color: #008fe1;\n    color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
