import { SqueegeeEvent } from '../Events/SqueegeeEvent';
import type { UploadItem } from './UploadItem';

export enum UploadEventType {
    FAILED,
    RETRY
}

export class UploadEvent extends SqueegeeEvent {
    constructor(public type: UploadEventType, public uploadItem: UploadItem) {
        super();
    }
}

export default UploadEvent;
