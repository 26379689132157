import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { ProductLevel } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { UserService } from '../../Users/UserService';
import './users-limit.scss';
export class UsersLimitCustomElement {
    applicationState = ApplicationState;
    @bindable account?: ICustomerSummary;

    progress = '0';

    navigateToUsers() {
        ApplicationState.router.navigateToRoute('users');
    }

    @computedFrom('applicationState', 'account')
    get usersText() {
        if (!this.account) return;
        const active = UserService.getActiveUsers().length;
        const hasInfinite = ApplicationState.hasMinimumSubscription(ProductLevel.Infinite);
        const limit = !hasInfinite && this.account.subscriptions?.length ? this.account.subscriptions[0].plans[0].quantity : 1;
        this.progress = (active / limit).toFixed(1);
        return ApplicationState.localise('account.active-users-limit', { active: active.toString(), limit: hasInfinite ? 'No Max' : limit.toString() });
    }
}
