export const getRedirectOnSuccessUrl = (): string | null => {
    // This will parse the query string part of the URL.
    const queryParams = new URLSearchParams(window.location.search);
    // This will retrieve the value of 'redirectOnSuccessUrl' if it exists, otherwise null.
    let redirectOnSuccessUrl = queryParams.get('redirectOnSuccessUrl');

    if (redirectOnSuccessUrl) {
        redirectOnSuccessUrl = decodeURIComponent(redirectOnSuccessUrl)
    }
    return redirectOnSuccessUrl;
}