import { uuid } from '@nexdynamic/squeegee-common';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { UploadManager } from '../Upload/UploadManager';
import UploadEvent, { UploadEventType } from './UploadEvent';

export type UploadItemType = 'attachment';
export abstract class UploadItem {
    public failed = false;
    public progress: number;
    id = uuid();
    constructor(public type: UploadItemType, protected file: File) { }

    progressHandler = (event: ProgressEvent) => {
        if (event) {
            this.progress = Math.round((event.loaded * 100) / event.total);
        }
    };
    errorHandler = (error: any) => {
        this.failed = true;
        Logger.error('Error uploading attachment', error);
        new UploadEvent(UploadEventType.FAILED, this);
        new NotifyUserMessage('uploads.failed', { fileName: this.file.name || '' });
    };

    public remove() {
        UploadManager.remove(this);
    }

    public addToQueue() {
        UploadManager.add(this);
    }

    public retry() {
        this.failed = false;
        new UploadEvent(UploadEventType.RETRY, this);
        this.upload();
    }

    public abstract cancel(): void;
    public abstract upload(): Promise<void>;
}
