import type { Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Prompt } from '../Dialogs/Prompt';
import { Logger } from '../Logger';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Utilities } from '../Utilities';
import { TeamFormDialog } from './TeamFormDialog';

export class TeamDialog extends CustomDialog<Team> {
    protected moreActions: Array<IMenuBarAction>;

    constructor(private team: Team) {
        super('teamDialog', '../Users/TeamDialog.html', team.name as TranslationKey, {
            cssClass: 'team-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });
        this.team = Utilities.copyObject(team);

        this.moreActions = [
            {
                tooltip: 'menubar.edit',
                actionType: 'action-edit',
                handler: this.edit,
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'menubar.delete',
                actionType: 'action-delete',
                handler: this.deleteTeam,
                roles: ['Owner', 'Admin', 'Creator'],
            },
        ];
    }

    private deleteTeam = async () => {
        if (
            await new Prompt('general.delete', <TranslationKey>`Are you sure you wish to delete the team ${this.team.name || ''}?`, {
                okLabel: 'general.delete',
                cancelLabel: 'general.cancel',
            }).show()
        ) {
            await Data.delete(this.team);
            this.cancel();
        }
    };

    private edit = async () => {
        const dialog = new TeamFormDialog(this.team);
        let team = await dialog.show();

        if (dialog.cancelled) return;

        team = Utilities.copyObject(team);
        this.team = team;

        await Data.put(team).catch(error => {
            Logger.error('Error in saving team', { team, error });
            new NotifyUserMessage('users.save-error');
        });
    };
}
