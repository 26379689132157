export const amusingConsoleMessages = [
    "I don't always test my code, but when I do, I do it in production.",
    'To the optimist, the glass is half full. To the pessimist, the glass is half empty. To the developer, the glass is twice as big as it needs to be.',
    '99 little bugs in the code, 99 little bugs. Take one down, patch it around, 127 little bugs in the code.',
    'Real programmers count from 0.',
    "I'm not lazy, I'm just highly optimized.",
    'It works on my machine.',
    'Why do Java developers wear glasses? Because they don’t C#.',
    'Code never lies, comments sometimes do.',
    'Programming is 10% writing code and 90% understanding why it’s not working.',
    'The best thing about a boolean is even if you are wrong, you are only off by a bit.',
    'There are two hard things in computer science: cache invalidation, naming things, and off-by-one errors.',
    "A SQL query goes into a bar, walks up to two tables and asks, 'Can I join you?'",
    'If at first you don’t succeed; call it version 1.0.',
    'I would love to change the world, but they won’t give me the source code.',
    'In theory, there is no difference between theory and practice. In practice, there is.',
    'There are 10 types of people in the world: those who understand binary, and those who don’t.',
    "A programmer's wife tells him: 'While you’re at the store, buy some milk.' He never comes back.",
    'The hardest part of programming is thinking up the names.',
    'If you put a million monkeys at a million keyboards, eventually one of them will write a Java program. The rest of them will write Perl programs.',
    "I'm not a great programmer; I'm just a good programmer with great habits.",
    'Knock, knock. Who’s there? Recursion. Recursion who? Knock, knock.',
    'To err is human, to forgive divine. Neither is the developer’s job.',
    'Programming is like writing a book... except if you miss a single comma on page 126 the whole thing makes no sense.',
    'The computer was born to solve problems that did not exist before.',
    'Software and cathedrals are much the same – first we build them, then we pray.',
    'First, solve the problem. Then, write the code.',
    'Copy-and-Paste was programmed by programmers for programmers actually.',
    'One man’s crappy software is another man’s full-time job.',
    'It’s not a bug – it’s an undocumented feature.',
    'If debugging is the process of removing software bugs, then programming must be the process of putting them in.',
    'Computers are fast; programmers keep it slow.',
    'In order to understand recursion, one must first understand recursion.',
    'There are two major products that come out of Berkeley: LSD and UNIX. We don’t believe this to be a coincidence.',
    'A good programmer is someone who always looks both ways before crossing a one-way street.',
    'When I wrote this code, only God and I understood what I did. Now only God knows.',
    "Software undergoes beta testing shortly before it’s released. Beta is Latin for 'still doesn’t work.'",
    "It's not a bug, it's a feature.",
    "If it works, don't touch it.",
    'To understand what recursion is, you must first understand recursion.',
    'I think Microsoft named .Net so it wouldn’t show up in a Unix directory listing.',
    'Programming is like being in a dark room and looking for a black cat.',
    'It’s not a bug, it’s an unexpected feature.',
    'There’s no place like 127.0.0.1.',
    'Algorithm: Word used by programmers when they do not want to explain what they did.',
    'Programming is like playing the piano – you can’t hit all the keys at once.',
    'A good algorithm should work in theory, even if it doesn’t work in practice.',
    'I don’t always test my code, but when I do, I do it in production.',
    'Weeks of programming can save you hours of planning.',
    'A program that produces incorrect results twice as fast is infinitely slower.',
    'Programmer: A machine that turns coffee into code.',
    'The most likely way for the world to be destroyed, most experts agree, is by accident. That’s where we come in; we’re computer professionals. We cause accidents.',
    "Don't worry if it doesn't work right. If everything did, you'd be out of a job.",
    'In a room full of top software designers, if two agree on the same thing, that’s a majority.',
    "You know you're a programmer when your Google search history is filled with semicolons.",
    'Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code.',
    'Why do programmers prefer dark mode? Because light attracts bugs.',
    'Real programmers don’t write documentation. If it was hard to write, it should be hard to understand.',
    'If Java had true garbage collection, most programs would delete themselves upon execution.',
    'A syntax error a day keeps the debugger away.',
    'A language that doesn’t affect the way you think about programming is not worth knowing.',
    'It compiles; ship it.',
    'Programming is like a multiplayer game: when you fix a bug, you create more bugs for your friends.',
    'You are in a maze of twisty little code, all alike.',
    'The best code is no code at all.',
    'Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live.',
    'In programming, the hard part isn’t solving problems, it’s deciding what problems to solve.',
    'If brute force doesn’t solve your problems, then you aren’t using enough.',
    'Walking on water and developing software from a specification are easy if both are frozen.',
    'Programming is like humor. If you have to explain it, it’s bad.',
    'The first 90 percent of the code accounts for the first 90 percent of the development time. The remaining 10 percent of the code accounts for the other 90 percent of the development time.',
    'If it wasn’t for C, we’d all be programming in BASI and OBOL.',
    'Beware of bugs in the above code; I have only proved it correct, not tried it.',
    'Before software can be reusable it first has to be usable.',
    'The function of good software is to make the complex appear to be simple.',
    'If you don’t handle exceptions, you’ll be an exception.',
    'The only valid measurement of code quality: WTFs/minute.',
    'Measuring programming progress by lines of code is like measuring aircraft building progress by weight.',
    'If you optimize everything, you will always be unhappy.',
    'Code is like humor. When you have to explain it, it’s bad.',
    'When you are stuck, keep looking until you find the solution. But in programming, it’s the compiler that usually finds it for you.',
    'Programming can be fun, so can cryptography; however, they should not be combined.',
    "You don't need to know how to swim to jump in at the deep end.",
    'A misplaced semi-colon is all that stands between you and a functioning code.',
    'Perl – The only language that looks the same before and after RSA encryption.',
    'Debugging: Removing the needles from the haystack.',
    "Software testing is like checking the navigation system after you've reached your destination.",
    'A bug in code is worth two in the backlogs.',
    'A good programmer is like a magician; they don’t reveal their secrets.',
    'Some days it’s hard to find enough coffee to keep up with the errors.',
    "There's nothing more permanent than a temporary hack.",
    'Your code is broken, and now the whole stack is on fire.',
    'Why do programmers confuse Christmas and Halloween? Because Oct 31 == Dec 25.',
    'Code like it’s the 90s; document like it’s the 80s.',
    'Turning coffee into code, one bug at a time.',
    'The first rule of programming: if it works, don’t touch it.',
    'I love deadlines. I like the whooshing sound they make as they fly by.',
    'Keep calm and code on.',
    'Error 404: Funny quote not found.',
    'Computers make very fast, very accurate mistakes.',
    "Sometimes the best debugging tool is a good night's sleep.",
];
