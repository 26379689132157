import { AuthorisedUser, copyObject } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { PermissionsUpdateEvent } from '../Events/PermissionsUpdateEvent';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { UserService } from './UserService';

export class UserAuthorisationService {
    static deactivateUser(email: string): any {
        if (email === ApplicationState.dataEmail) return;

        const auth = this.getUserAuthorisation(email);
        if (auth) {
            auth.deactivated = true;
            UserAuthorisationService.updateAuthorisation(auth);
        }
    }

    public static isDeactivated(email: string, authorisation: AuthorisedUser | null) {
        return email !== ApplicationState.dataEmail && (!authorisation || authorisation.deactivated);
    }

    public static get userAuths() {
        const userAuths = ApplicationState.account.authorisedUsers || {};
        if (!userAuths[ApplicationState.dataEmail]) {
            userAuths[ApplicationState.dataEmail] = UserAuthorisationService.ownerAuthorisation;
            ApplicationState.save();
        }
        return userAuths;
    }

    public static getUserAuthorisation(email: string): AuthorisedUser | null {
        email = email.toLowerCase();
        const auth = UserAuthorisationService.userAuths[email];
        return auth ? copyObject(auth) : null;
    }

    private static _ownerAuthorisation: AuthorisedUser;
    public static get ownerAuthorisation(): AuthorisedUser {
        const dataEmail = ApplicationState.dataEmail;
        if (!UserAuthorisationService._ownerAuthorisation && dataEmail) {
            UserAuthorisationService._ownerAuthorisation = new AuthorisedUser();
            UserAuthorisationService._ownerAuthorisation.dataEmail = dataEmail;
            UserAuthorisationService._ownerAuthorisation.dataOwnerName = ApplicationState.account.name;
            UserAuthorisationService._ownerAuthorisation.userEmail = dataEmail;
            UserAuthorisationService._ownerAuthorisation.userName = ApplicationState.account.name;
            UserAuthorisationService._ownerAuthorisation.roles = ['Owner', 'Admin', 'Creator', 'Planner', 'Worker'];
        }

        return UserAuthorisationService._ownerAuthorisation;
    }

    public static licenceCheck() {
        const licencedUsers = ApplicationState.subscription.users || 1;
        const activeUsers = UserService.getActiveUsers().length;

        return {
            licencedUsers,
            activeUsers,
            canAdd: activeUsers < licencedUsers
        }
    }

    private static _updateOwnerDetailsFromAuthUserUpdate(updatedAuthUser: AuthorisedUser) {
        if (updatedAuthUser.userEmail === ApplicationState.dataEmail) {
            ApplicationState.instance.account.name = updatedAuthUser.userName;
            ApplicationState.save();
            ApplicationState.refreshOwnerAuthorisation();
            new PermissionsUpdateEvent();
        }
    }

    public static updateAuthorisation(updatedAuthUser: AuthorisedUser): void {

        const userIsCurrentUser = updatedAuthUser.userEmail === (RethinkDbAuthClient.session && RethinkDbAuthClient.session.email);
        const userIsDataOwner = updatedAuthUser.userEmail === ApplicationState.dataEmail;
        if (userIsCurrentUser && userIsDataOwner) this._updateOwnerDetailsFromAuthUserUpdate(updatedAuthUser);

        updatedAuthUser.dataEmail = ApplicationState.account.email.toLowerCase();
        updatedAuthUser.userEmail = updatedAuthUser.userEmail.toLowerCase();
        updatedAuthUser.dataOwnerName = ApplicationState.account.businessName || ApplicationState.account.name;

        // Remove any existing authorisation for this user
        for (const userEmailKey in UserAuthorisationService.userAuths) {
            const existingAuthUser = UserAuthorisationService.userAuths[userEmailKey];
            if (
                existingAuthUser &&
                updatedAuthUser.userEmail !== userEmailKey &&
                existingAuthUser.userEmail === updatedAuthUser.userEmail
            ) {
                UserAuthorisationService.userAuths[userEmailKey] = null;
            }
        }
        UserAuthorisationService.userAuths[updatedAuthUser.userEmail] = updatedAuthUser;
        ApplicationState.account.authorisedUsers = UserAuthorisationService.userAuths;
        ApplicationState.save();
    }
}
