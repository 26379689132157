import type { Device } from '@nexdynamic/squeegee-common';
import { sortByUpdatedDateDesc } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { Data } from '../Data/Data';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { Logger } from '../Logger';

export class Security {
    protected deviceList: Array<Device>;
    protected deviceListFiltered: Array<Device> = [];

    @bindable public searchText = '';
    public searchTextChanged(): void {
        this.filter();
    }

    private _applicationStateUpdatedSub: Subscription;
    private _dataChangedSub: Subscription;
    public attached() {
        this.loadDevices();

        this._applicationStateUpdatedSub = ApplicationStateUpdatedEvent.subscribe(() => {
            this.loadDevices();
        });

        this._dataChangedSub = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.hasAnyType('devices')) {
                this.loadDevices();
            }
        });
    }

    public loadDevices() {
        this.deviceList = Data.all<Device>('devices').slice();
        this.filter();
    }

    protected filter() {
        //Splice hack to make aurelia array obersvable update todo make filtering not reassign the array
        this.deviceListFiltered.splice(0);
        this.deviceListFiltered = this.deviceList
            .filter((data: Device) => {
                const check = [data._id, data.manufacturer, data.model, data.platform, data.serial, data.updatedByUser, data.createdBy]
                    .filter(e => !!e)
                    .join(' ');
                return this.searchText.length === 0 || check.toLowerCase().includes(this.searchText.toLowerCase());
            })
            .sort(sortByUpdatedDateDesc);

        Logger.info('Filtered list equals', this.deviceListFiltered);
    }

    protected detached() {
        this._dataChangedSub && this._dataChangedSub.dispose();
        this._applicationStateUpdatedSub && this._applicationStateUpdatedSub.dispose();
    }
}
