import type { Disposable } from 'aurelia-framework';
import { BindingEngine, computedFrom, inject } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { UploadManager } from '..//UploadManager';
import UploadEvent from '../UploadEvent';
import type { UploadItem } from '../UploadItem';
@inject(BindingEngine)
export class UploadingIcon {
    uploads: Array<UploadItem> = [];

    constructor(private bindingEngine: BindingEngine) { }
    private uploadsSub?: Disposable;
    private uploadEventSub?: Disposable;
    attached() {
        this.uploadsSub = this.bindingEngine
            .collectionObserver(UploadManager.uploads)
            .subscribe(() => (this.uploads = UploadManager.getUploads()));
        this.uploadEventSub = UploadEvent.subscribe(() => {
            this.uploads = UploadManager.getUploads()
        });
        this.uploads = UploadManager.getUploads();
    }

    detached() {
        this.uploadsSub?.dispose();
        this.uploadEventSub?.dispose();
    }

    viewAttachments() {
        ApplicationState.router.navigate('attachments');
    }

    @computedFrom('uploads')
    get color() {
        if (this.uploads && this.uploads.find(item => item.failed)) {
            return 'error';
        }
        return 'success';
    }
}
