import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { searchScheduleItems, wait } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { ApplicationState } from '../../ApplicationState';
import type { LocationWithScheduleItem } from '../../Components/Map';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { ActionBarEvent } from '../../Events/ActionBarEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { InvalidateMapSizesEvent } from '../../Events/InvalidateMapSizesEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { getAdditionalInfoForScheduleItems } from '../../Schedule/getAdditionalInfoForScheduleItems';
import { SelectedItemsDialog } from './SelectedItemsDialog';
import './VisualPlanner.scss';
export class VisualPlanner {
    private _dataRefreshedEvent: Subscription;
    protected scheduleItems: Array<ScheduleItem>;
    protected selectedItems?: Array<ScheduleItem>;
    protected clearSelected: () => void;
    protected rounds: Array<TranslationKey>;
    protected refreshSelected?: () => void;
    protected searchText: string;
    private _dataRefreshDebounce: NodeJS.Timeout;

    public async attached() {
        new LoaderEvent(true);
        await this.loadScheduleItems();
        new LoaderEvent(false);

        this._dataRefreshedEvent = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (!event.hasAnyType('joboccurrences', 'customers', 'tags')) return;
            this.debouncedRefresh();
        });
    }

    private debouncedRefresh = () => {
        clearTimeout(this._dataRefreshDebounce);

        this._dataRefreshDebounce = setTimeout(async () => {
            await this.refresh();
            this.refreshSelected?.();
        }, 250);
    };

    private async loadScheduleItems() {
        this.scheduleItems = await ScheduleService.getAllActiveJobsAsScheduleItems();
    }

    public async detached() {
        this._dataRefreshedEvent?.dispose();
        this._selectedItemsDialog?.cancel();
    }

    private _selectedItemsDialog?: SelectedItemsDialog;
    public selectItems = async (items: Array<ScheduleItem>) => {
        this.selectedItems = items;
        this.setSelectableMode();

        if (this.selectedItems?.length) {
            this._selectedItemsDialog = new SelectedItemsDialog(this.selectedItems);
            this._selectedItemsDialog.show(DialogAnimation.SLIDE);
        } else {
            this._selectedItemsDialog?.cancel();
        }
        await wait(375);
        new InvalidateMapSizesEvent();
    };

    protected selectItem = (locationWithScheduleItem: LocationWithScheduleItem) => {
        locationWithScheduleItem?.scheduleItem && new ScheduleDetailsDialog(locationWithScheduleItem.scheduleItem).show();
    };

    protected setSelectableMode = () => {
        if (!this.selectedItems?.length) return new ActionBarEvent(false);

        ActionBarEvent.fromSelectedScheduleItems(
            this.clearSelected,
            ScheduleService.getBulkActionsForScheduleItem(this.selectedItems, this.scheduleItems, this.bulkActionWrapper, true),
            this.selectedItems || []
        );
    };

    private bulkActionWrapper = async (
        action: (scheduleItems: Array<ScheduleItem>, unselectMethod: () => void, allScheduleItems?: Array<ScheduleItem>) => Promise<any>,
        message: TranslationKey,
        allScheduleItems?: Array<ScheduleItem>
    ) => {
        const selectedItems = this.selectedItems;
        if (selectedItems) {
            try {
                await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));

                const happened = await action(selectedItems, () => undefined, allScheduleItems);

                await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));

                if (happened && this.scheduleItems.length > 1) {
                    this.refresh();
                    requestAnimationFrame(() => {
                        new NotifyUserMessage(<TranslationKey>(selectedItems.length.toString() + ' ' + message), {
                            count: selectedItems.length.toString(),
                        });
                    });
                }
            } catch (error) {
                new NotifyUserMessage('notifications.oh-no');
                Logger.error(`Error during bulk action wrapper in schedule item list custom element`, { action, error });
            } finally {
                new LoaderEvent(false);
            }
        }
    };

    private refresh = async () => {
        let scheduleItems = await ScheduleService.getAllActiveJobsAsScheduleItems();
        if (this.searchText?.trim().length) {
            const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',').map(x => x.trim());
            const additional = getAdditionalInfoForScheduleItems(scheduleItems);
            scheduleItems = searchScheduleItems<ScheduleItem>(scheduleItems, this.searchText, undefined, mobilePrefixes, additional);
        }
        this.scheduleItems = scheduleItems;
        const ids = scheduleItems.map(s => s.occurrence._id);
        this.selectedItems = this.selectedItems?.filter(s => ids.includes(s.occurrence._id));
    };

    protected executeSearch = () => {
        this.debouncedRefresh();
    };
}
