import type { Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { AccountUser } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Prompt } from '../Dialogs/Prompt';
import { Logger } from '../Logger';
import { Utilities } from '../Utilities';
import type { AssignmentSummary } from './Assignees/AssignmentService';
import { AssignmentService } from './Assignees/AssignmentService';
import { UserService } from './UserService';

export class SelectUserOrTeamDialog extends CustomDialog<AccountUser | Team | undefined> {
    public filteredList: Array<AssignmentSummary>;
    public searchTerm = '';

    constructor(
        public selectedAccountUserOrTeam?: AssignmentSummary,
        protected unassignedTitle = ApplicationState.localise('general.unassigned'),
        protected unassignedDescription = 'Do not assign to a team or worker',
        protected date?: string,
        protected overrideTitle: TranslationKey = 'dialogs.select-user-title',
        protected settingDefaultUser: boolean | undefined = false
    ) {
        super('selectUserOrTeamDialog', '../Users/SelectUserOrTeamDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'select-list-dialog select-dialog-with-search no-nav-shadow',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });
        this._init();
    }

    private _list: Array<AssignmentSummary> = [];

    protected _delegateOk() {
        if (!this.selectedAccountUserOrTeam) return;

        if (this.selectedAccountUserOrTeam.id === 'UNASSIGNED') {
            const user = new AccountUser('');
            user._id = 'UNASSIGNED';
            user.name = ApplicationState.localise('general.unassigned');
            return this.ok(user);
        }

        const assignee = Data.get<AccountUser | Team>(this.selectedAccountUserOrTeam.id);
        this.ok(assignee);
    }

    private _users: Array<AccountUser>;
    public async load() {
        try {
            const teams = await UserService.getTeams(this.date);
            this._users = await UserService.getWorkers();

            //if no default user, add unassigned
            const defaultAssignee = AssignmentService.getDefaultAssigneeUserOrTeam();

            if (this.settingDefaultUser || !defaultAssignee) {
                const unassigned: AssignmentSummary = {
                    id: 'UNASSIGNED',
                    name: ApplicationState.localise('general.unassigned'),
                    type: 'unassigned',
                    skills: [],
                    description: this.unassignedDescription,
                };

                this._list.push(unassigned);
            } else if (defaultAssignee) {
                this.unassignedTitle = `Default Assignee (Currently: ${defaultAssignee.name})` as TranslationKey;
                this.unassignedDescription = 'Set selected jobs to the default assignee';

                const defaultAssigneeSummary: AssignmentSummary = {
                    id: 'UNASSIGNED',
                    name: `Default Assignee (${defaultAssignee.name})`,
                    type: 'unassigned',
                    description: 'Set selected jobs to the default assignee',
                    skills: [],
                };
                this._list.push(defaultAssigneeSummary);
            }
            if (ApplicationState.multiUserEnabled) {
                for (const team of teams) this._list.push(AssignmentService.assigneeToSummary(team));
            }
            for (const user of this._users) this._list.push(AssignmentService.assigneeToSummary(user));
            this.filter();
        } catch (error) {
            Logger.error('Error in load() on SelectUserDialog', error);
        }
    }

    public filter() {
        this.filteredList = this._list.filter(userOrTeam => {
            return (
                !this.searchTerm ||
                (this.searchTerm && userOrTeam.name && userOrTeam.name.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1)
            );
        });
    }

    private _init() {
        this.load();
    }

    protected selectUserOrTeam(user: AssignmentSummary) {
        this.selectedAccountUserOrTeam = user;
        this.ok();
    }

    public async getResult() {
        if (!this.selectedAccountUserOrTeam) return;

        if (this.selectedAccountUserOrTeam.id === 'UNASSIGNED') {
            const user = new AccountUser('');
            user._id = 'UNASSIGNED';
            user.name = ApplicationState.localise('general.unassigned');
            return user;
        }

        const assignee = Data.get<AccountUser | Team>(this.selectedAccountUserOrTeam.id);
        return assignee;
    }

    protected getColour(userOrTeam: AssignmentSummary) {
        return userOrTeam.type === 'unassigned' ? '#888' : `#${Utilities.hexFromString(userOrTeam.name || 'unknown')}`;
    }

    protected getIcon(userOrTeam: AssignmentSummary) {
        return userOrTeam.type === 'unassigned' ? 'remove' : userOrTeam.type === 'team' ? 'people' : 'person';
    }

    protected getLimitedSkills(userOrTeam: AssignmentSummary) {
        const skills = userOrTeam.skills || [];
        return skills.length > 3 ? skills.slice(0, 3) : skills;
    }

    protected async showSkills(userOrTeam: AssignmentSummary, event: Event) {
        event.stopPropagation();
        await new Prompt(
            `${userOrTeam.name}'s Skills` as TranslationKey,
            `${userOrTeam.skills?.map(s => s).join(', ')}` as TranslationKey,
            {
                cancelLabel: '',
            }
        ).show();
    }
}
