import type { AccountUser, Job, Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { FrequencyType, JobOccurrence } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { prompt } from '../../Dialogs/ReactDialogProvider';
import { JobService } from '../../Jobs/JobService';
import { SelectUserOrTeamDialog } from '../../Users/SelectUserOrTeamDialog';
import { Utilities } from '../../Utilities';
import type { AssignmentSummary } from './AssignmentService';
import { AssignmentService } from './AssignmentService';

export class AssigneesCustomElement {
    @bindable() jobOccurrence: JobOccurrence | Job;

    @bindable() assigneeSummaries: Array<AssignmentSummary> = [];
    @bindable() assignees: Array<AccountUser | Team> = [];

    @bindable() saveOnAssignment = false;
    attached() {
        if (!this.assignees?.length) {
            this.assigneeSummaries = AssignmentService.getAssigneeSummaries(this.jobOccurrence);
            this.assignees = AssignmentService.getAssignees(this.jobOccurrence);
        } else {
            this.assigneeSummaries = this.assignees.map(assignee => AssignmentService.assigneeToSummary(assignee));
        }
    }

    multiAssigneeEnabled = ApplicationState.hasUltimateOrAbove;

    protected isAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected isEditor = ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'JobEditor']);
    protected isPlanner = ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'JobEditor', 'Planner']);

    @computedFrom('assignees', 'assignees.length')
    get canAdd() {
        return ApplicationState.hasUltimateOrAbove || this.assigneeSummaries?.length === 0;
    }

    @computedFrom('assignees', 'assignees.length')
    get assignee() {
        return this.assigneeSummaries?.[0]?.name || ApplicationState.localise('general.unassigned');
    }

    async add() {
        this.protectArchivedAssignees();
        const date =
            this.jobOccurrence.resourceType === 'joboccurrences' ? JobOccurrence.getDate(this.jobOccurrence as JobOccurrence) : undefined;

        const dialog = new SelectUserOrTeamDialog(undefined, undefined, undefined, date);
        const assignee = await dialog.show(DialogAnimation.SLIDE_UP);

        if (dialog.cancelled) return;
        if (!assignee) return;

        if (assignee._id === 'UNASSIGNED') {
            this.assignees = [];
            this.assigneeSummaries.splice(0);
        } else {
            if (this.multiAssigneeEnabled) {
                if (this.assigneeSummaries.find(x => x.id === assignee._id)) return;
            } else {
                this.assigneeSummaries.splice(0);
                this.assignees.splice(0);
            }
            const assignmentSummary = AssignmentService.assigneeToSummary(assignee);
            this.assigneeSummaries.push(assignmentSummary);
            this.assignees.push(assignee);
        }

        if (this.saveOnAssignment) {
            await this.doActualSaveOnAssignment();
        }
    }

    async doActualSaveOnAssignment() {
        let updateJobToo = false;
        if (this.isEditor && this.jobOccurrence.resourceType === 'joboccurrences') {
            const occ = this.jobOccurrence as JobOccurrence;
            const job = JobService.getJob(occ.customerId, occ.jobId);
            if (job) {
                const isAdHoc = job.frequencyType === FrequencyType.NoneRecurring;
                const title = (isAdHoc ? 'Ad Hoc Job' : 'Repeating Job') as TranslationKey;
                const messageKey = isAdHoc ? 'workload.update-all-or-one-adhoc' : 'workload.update-all-or-one-repeating';

                const localisationParams = { fields: Utilities.localiseList(['field-name.assignees']) };

                const prompt = new Prompt(title, messageKey, {
                    okLabel: 'general.ok-label-just-this',
                    cancelLabel: 'general.cancel',
                    altLabel: 'general.all-title',
                    localisationParams,
                });

                const updateJustThisOne = await prompt.show();
                if (prompt.cancelled) {
                    const assignees = AssignmentService.getAssignees(this.jobOccurrence);
                    const summaries = AssignmentService.getAssigneeSummaries(this.jobOccurrence);
                    this.assignees = assignees;
                    this.assigneeSummaries = summaries;
                    return;
                }
                updateJobToo = !updateJustThisOne;
            }
        }
        await AssignmentService.assign(
            this.jobOccurrence,
            this.assignees.map(x => x._id),
            updateJobToo,
            true
        );

        this.assigneeSummaries = AssignmentService.getAssigneeSummaries(this.jobOccurrence);
        this.assignees = AssignmentService.getAssignees(this.jobOccurrence);
    }

    async unassign(assignee: AssignmentSummary) {
        this.protectArchivedAssignees();
        const idx = this.assigneeSummaries.indexOf(assignee);
        if (idx === -1) return;
        this.assigneeSummaries.splice(idx, 1);

        const idx2 = this.assignees.findIndex(x => x._id === assignee.id);
        if (idx2 === -1) return;
        this.assignees.splice(idx, 1);

        if (!this.saveOnAssignment) return;
        await AssignmentService.unassign(this.jobOccurrence, assignee.id);
        this.assigneeSummaries = AssignmentService.getAssigneeSummaries(this.jobOccurrence);
    }

    private protectArchivedAssignees() {
        if (!this.jobOccurrence._archived) return;
        if (ApplicationState.stateFlags.devMode) return;
        prompt('general.error', 'appointments.appointment-archived-editing-disabled', { cancelLabel: '' }).show();
        throw new Error('This occurrence is archived and cannot be edited.');
    }
}
