import type { AccountUser } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom, inject } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Utilities } from '../../Utilities';
import type { Workload } from '../Workload';

@inject(Element)
export class WorkLoadAvatar {
    @bindable() workload: Workload;
    @bindable() simple: boolean;
    @bindable() hideDonut: boolean;
    @bindable() ondropped?: (data: string, workload: Workload) => void;

    protected ready: boolean;
    constructor(protected _element: HTMLElement) { }
    protected workloadChanged() {
        this.workload.updateSeries(this.workload.series);
        requestAnimationFrame(() => (this.ready = true));
    }

    protected getIcon(resourceType: string) {
        if (this.workload.userOrTeam?.avatar) return;
        if (this.workload.userOrTeam?._id === 'UNASSIGNED') return 'person';

        switch (resourceType) {
            case 'team':
                return 'people';
            case 'accountuser':
                return 'person';
            case 'all':
                return 'public';
            default:
                return '';
        }
    }

    public getBackground() {
        if (this.workload.userOrTeam?.avatar) {
            return `background-image:url(${this.workload.userOrTeam?.avatar})`;
        } else return `background-color: ${this.avatarColor}`;
    }

    @computedFrom('workload', 'workload.userOrTeam', 'workload.userOrTeam.name')
    public get avatarText() {
        if (!this.workload || !this.workload.userOrTeam || !((<AccountUser>this.workload.userOrTeam).email || this.workload.userOrTeam._id))
            return '';
        if (this.workload.userOrTeam?.avatar) return;

        if (this.workload.userOrTeam.name && this.workload.userOrTeam.name.trim().length > 0) {
            return Utilities.getAvatarTextFromName(this.workload.userOrTeam.name);
        }
    }
    @computedFrom('workload', 'workload.userOrTeam', 'workload.userOrTeam.name')
    public get avatarColor() {
        const name =
            (this.workload.userOrTeam &&
                this.workload.userOrTeam.name &&
                this.workload.userOrTeam.name.trim().length > 0 &&
                this.workload.userOrTeam.name.trim()) ||
            '';
        if (!name) return '#486493';
        return `#${Utilities.hexFromString(name)}`;
    }

    @computedFrom('workload', 'workload.userOrTeam', 'workload.userOrTeam.name')
    public get text() {
        if (this.workload && this.workload.userOrTeam && this.workload.userOrTeam.name) {
            return this.workload.userOrTeam.name;
        } else {
            return ApplicationState.localise('general.unassigned');
        }
    }
}
