import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { UploadItem } from '../UploadItem';

export class UploadItemDialog extends CustomDialog<void> {
    public constructor(public title: TranslationKey, private uploadItem: UploadItem) {
        super('uploadItemDialog', '../Upload/Dialogs/UploadItemDialog.html', ApplicationState.localise(title), { okLabel: '' });
    }

    public startUpload = async () => {
        try {
            await this.uploadItem.upload();
            this.ok();
        } catch {
            this.cancel();
        }
    };

    public async onCancel() {
        this.uploadItem.cancel();
        return true;
    }
}
