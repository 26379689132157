import type { AccountUser, AlertType, AuthorisedUser } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Logger } from '../../Logger';
import { UserAuthorisationService } from '../../Users/UserAuthorisationService';
import { UserDialog } from '../UserDialog';

export class UserListItem {
    @bindable() user: AccountUser;
    @bindable() pushAlerts: Array<AlertType>;

    protected deactivated: boolean;
    protected authorisation: AuthorisedUser | null;
    protected localise = ApplicationState.localise;

    attached() {
        this.authorisation = UserAuthorisationService.getUserAuthorisation(this.user.email);
        this.deactivated = UserAuthorisationService.isDeactivated(this.user.email, this.authorisation);
    }

    @computedFrom('user.name')
    get title() {
        return this.user.name;
    }

    @computedFrom('user.avatar')
    get avatar() {
        return this.user.avatar;
    }

    @computedFrom('user.email')
    get description() {
        return this.user.email;
    }

    @computedFrom('authorisation', 'authorisation.roles', 'deactivated')
    get tags() {
        if (this.authorisation) {
            if (this.authorisation.deactivated) return [ApplicationState.localise('general.deactivated')];
            return this.authorisation.roles?.map(role => ApplicationState.localise(`role.${role}`));
        }
        return [];
    }

    protected async viewUser() {
        try {
            const dialog = new UserDialog(this.user);
            await dialog.show();
            this.pushAlerts = ApplicationState.getPushAlertsForUser(this.user.email);
        } catch (error) {
            Logger.error('Unable to view user', { user: this.user, error });
        }
    }
}
