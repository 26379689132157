import type { AccountUser, Team } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { SelectMultiple } from '../../Dialogs/SelectMultiple';
import { UserDialog } from '../../Users/UserDialog';
import { UserService } from '../../Users/UserService';
import { UserAuthorisationService } from '../UserAuthorisationService';

export class TeamMembers {
    @bindable team: Team;
    teamChanged() {
        this.selectedMembers = UserService.getAllUsersOfTeam(this.team);
    }
    @bindable editable = true;
    //passed as object to enable callback to parse params
    @bindable onChange: ({ members }: { members: Array<AccountUser> }) => void;


    protected userIsDeactivated(user: AccountUser) {

        return UserAuthorisationService.isDeactivated(user.email, UserAuthorisationService.userAuths[user.email]);
    }

    protected selectedMembers: Array<AccountUser> = [];

    protected async add() {
        const members = UserService.getWorkers();
        const memberSelectDialog = new SelectMultiple('teams.select-team-member', members, 'name', '_id', this.selectedMembers.slice());

        const selected = await memberSelectDialog.show();
        if (!memberSelectDialog.cancelled) {
            this.selectedMembers = selected;
            this.onChange({ members: this.selectedMembers });
        }
    }

    async viewMember(user: AccountUser) {
        await new UserDialog(user).show();
        // Slicing the array before makes aurelia update
        this.selectedMembers.splice(0);
        this.selectedMembers = UserService.getAllUsersOfTeam(this.team);
    }

    remove(user: AccountUser) {
        this.selectedMembers.splice(this.selectedMembers.indexOf(user), 1);
        this.onChange({ members: this.selectedMembers });
    }
}
