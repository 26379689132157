import { AccountUser } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { FabWithActions } from '../FabWithActions';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { UserAuthorisationService } from './UserAuthorisationService';
import { UserDialog } from './UserDialog';
import { UserService } from './UserService';

export class Users {
    protected userList: Array<AccountUser>;
    protected userListFiltered: Array<AccountUser> = [];
    protected fabWithActions: FabWithActions;
    protected getAlerts(email: string) {
        return ApplicationState.getPushAlertsForUser(email);
    }

    protected userCount = ApplicationState.subscription && ApplicationState.subscription.users;
    @bindable public searchText = '';

    public searchTextChanged(): void {
        this.filter();
    }

    protected log(args: any) {
        Logger.info(args);
    }

    protected tabs = [
        { id: 'active' as const, label: 'Active' },
        { id: 'inactive' as const, label: 'Inactive' },
        { id: 'all' as const, label: 'All' }
    ];
    protected tab = this.tabs[0];
    protected switchTo(tab: typeof this.tabs[number]) {
        this.tab = tab;
        this.loadUsers();
    }

    private _applicationStateUpdatedSub: Subscription;
    private _dataChangedSub: Subscription;
    public attached() {
        this.loadUsers();
        FabWithActions.register([
            {
                tooltip: 'actions.add-new-user',
                actionType: 'action-new-customer',
                handler: this._delegateAddUser,
                roles: ['Owner', 'Admin'],
            },
        ]);
        this._applicationStateUpdatedSub = ApplicationStateUpdatedEvent.subscribe(() => {
            this.loadUsers();
        });

        this._dataChangedSub = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.hasAnyType('accountuser')) {
                this.loadUsers();
            }
        });
    }
    public loadUsers() {
        this.userList = UserService.getUsers();
        this.filter();
    }

    protected filter() {
        //Splice hack to make aurelia array observable update todo make filtering not reassign the array
        this.userListFiltered.splice(0);
        this.userListFiltered = this.userList
            .filter((data: AccountUser) => {
                let include = false;

                include =
                    this.tab.id === 'all' ||
                    (this.tab.id === 'active' && !UserAuthorisationService.isDeactivated(data.email, UserAuthorisationService.userAuths[data.email])) ||
                    (this.tab.id === 'inactive' && UserAuthorisationService.isDeactivated(data.email, UserAuthorisationService.userAuths[data.email]));

                include =
                    include &&
                    (this.searchText.length === 0 ||
                        (data.email && data.email.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
                        data.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1);
                return include;
            })
            .sort((a, b) => (b.name || '').localeCompare(a.name || ''));

    }

    private _delegateAddUser = () => this.addUser();

    protected async addUser() {
        try {
            const dialog = new UserDialog(new AccountUser(''), true);
            await dialog.show();
            if (!dialog.cancelled) {
                this.loadUsers();
            }
        } catch (error) {
            Logger.error(`Error during addUser in Users`, error);
            new NotifyUserMessage('users.save-error');
        }
    }

    protected detached() {
        this._dataChangedSub && this._dataChangedSub.dispose();
        this._applicationStateUpdatedSub && this._applicationStateUpdatedSub.dispose();
    }

    public deactivate() {
        FabWithActions.unregister();
    }
}
