import { bindable } from 'aurelia-framework';

export class UploadCardCustomElement {
    @bindable() name: string;
    @bindable() progress: number;
    @bindable() thumbnail: string;
    @bindable() failed: boolean;

    @bindable() mimeType: string;
    @bindable() onCancel: () => void;
    @bindable() onRemove: () => void;
    @bindable() onLoad?: () => void;
    @bindable() onRetry?: () => void;

    attached() {
        this.onLoad && this.onLoad();
    }

    cancel() {
        this.onCancel();
    }

    remove() {
        this.onRemove();
    }
}
